import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { separator } from '../../../../../Containers/Commons/Utils';
import { DECIMAL_SEPARATOR, FEE_TYPES, THOUSAND_SEPARATOR } from '../../../../../services/constantService';
import { useLanguageHooks } from '../../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';
import BrandSelection from '../../../../common/FunctionalComponents/BrandSelection';
import CurrencySelection from '../../../../common/FunctionalComponents/CurrencySelection';
import FeetypeSelection from '../../../../common/FunctionalComponents/FeetypeSelection';
import RegionSelection from '../../../../common/FunctionalComponents/RegionSelection';
import RevenueMarketSelection from '../../../../common/FunctionalComponents/RevenueMarketSelection';
import TermtypeIntervalSelection from '../../../../common/FunctionalComponents/TermtypeIntervalSelection';
import TermtypeSelection from '../../../../common/FunctionalComponents/TermtypeSelection';
import CustomInputField from '../../../../common/StyledComponents/CustomInputField';
import './index.scss';

const BillingForm = props => {
  const { register, watch, setValue, currentValues, currencyDetails, isHide, isEditing } = props;

  const { t } = useLanguageHooks([
    TRANS_KEYS.BD_BASIC_INFO,
    TRANS_KEYS.BD_OPPORTUNITY,
    TRANS_KEYS.BD_STAGE,
    TRANS_KEYS.BD_COMPANY,
    TRANS_KEYS.BD_CLIEN_CONTACTS,
    TRANS_KEYS.BD_JOB_TITLE,
    TRANS_KEYS.BD_PROJECTED_START_DATE,
    TRANS_KEYS.BD_LOCATION,
    TRANS_KEYS.BD_CONSULTANT,
    TRANS_KEYS.BD_PROBABILITY,
    TRANS_KEYS.BD_BD_STATUS,
    TRANS_KEYS.BD_SERVICE_OFFERING,
    TRANS_KEYS.BD_GEOGRAPHY,
    TRANS_KEYS.BD_COMPETITION
  ]);

  useEffect(() => {
    if (isEditing) {
      const field = [
        'job_number',
        'brand',
        'bd_currency',
        'bd_currency_values',
        'markets',
        'market',
        'is_fee_cap',
        'fee_cap_amount',
        'term_type',
        'intervals',
        'intervals_other',
        'billing_region',
        'fee_type',
        'fee_percentage',
        'billing_notes'
      ];
      field.forEach(fieldName => {
        if (fieldName === 'bd_currency_values') {
          setValue('bd_currency_values.code', currentValues[fieldName]?.code);
        } else if (fieldName === 'market') {
          setValue('market', currentValues['markets']);
        } else {
          setValue(fieldName, currentValues[fieldName]);
        }
      });
    }
  }, [currentValues]);

  return (
    <Box>
      <Box className='bd-container'>
        <Box className='bd-row'>
          <Box className='bd-input-box'>
            <CustomInputField label={`${t(`${TRANS_KEYS.BD_OPPORTUNITY}:key`)} #`} defaultValue={watch('job_number') || currentValues?.job_number || '--'} disabled />
          </Box>
          <Box className='bd-dropdown-box'>
            <BrandSelection
              defaultValue={watch('brand_desc') ? watch('brand_desc') : watch('brand') || currentValues?.service_offering_desc?.short_desc || currentValues?.service_offering || null || '--'}
              value={watch('brand_desc') ? watch('brand_desc') : watch('brand') || currentValues?.service_offering_desc?.short_desc || currentValues?.service_offering || null || '--'}
              label={t(`${TRANS_KEYS.BD_SERVICE_OFFERING}:key`)}
              required={true}
              disabled={true}
            />
          </Box>
        </Box>
      </Box>
      <Box className='bd-container'>
        <Box className='bd-row'>
          <Box className='bd-dropdown-box'>
            <CurrencySelection
              defaultValue={currentValues?.bd_currency_values?.code || watch('bd_currency_values.code') || null}
              onChange={(e, data) => {
                setValue('bd_currency', data ? data?.id : null, { shouldDirty: true });
                setValue('bd_currency_values.code', data?.label, { shouldDirty: true });
              }}
              label={'Currency'}
              disabled={isHide}
              required={true}
              style={{ width: '100%' }}
            />
          </Box>
          <Box className='bd-dropdown-box'>
            <RevenueMarketSelection
              defaultValue={watch('markets.market') || currentValues?.markets?.market || '--'}
              label={t(`${TRANS_KEYS.BD_GEOGRAPHY}:key`)}
              disabled={true}
              style={{ width: '100%' }}
            />
          </Box>
        </Box>
      </Box>
      <Box className='bd-container'>
        <Box className='bd-row'>
          <Box className='bd-input-box'>
            <Box className='switch-box'>
              <Typography className='p-2' variant='body2'>
                {'Fee Cap'}
              </Typography>
              <FormControlLabel
                className='mb-0'
                control={
                  <Switch
                    className='SwitchStyle'
                    checked={watch('is_fee_cap') || false}
                    onChange={e => {
                      const isChecked = e.target.checked;
                      setValue('is_fee_cap', isChecked, { shouldDirty: true });
                      if (!isChecked) {
                        setValue('fee_cap_amount', null);
                      }
                    }}
                  />
                }
              />
            </Box>
          </Box>
          <Box className='bd-input-box'>
            {watch('is_fee_cap') ? (
              <NumericFormat
                label={'Fee Cap Amount'}
                customInput={CustomInputField}
                fullWidth='true'
                name='fee_cap_amount'
                {...register('fee_cap_amount')}
                defaultValue={currentValues.fee_cap_amount}
                thousandSeparator={separator(watch('bd_currency_values.code'), THOUSAND_SEPARATOR)}
                decimalSeparator={separator(watch('bd_currency_values.code'), DECIMAL_SEPARATOR)}
                decimalScale={2}
                isNumericString={true}
                prefix={currencyDetails ? (currencyDetails.prefix ? `${currencyDetails.currency_icon} ` : '') : ''}
                suffix={currencyDetails ? (currencyDetails.prefix === false ? ` ${currencyDetails.currency_icon}` : '') : ''}
                onValueChange={values => {
                  const { value } = values;
                  setValue('fee_cap_amount', value, { shouldDirty: true });
                }}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box className='bd-container'>
        <Box className='bd-row'>
          <Box className='bd-dropdown-box'>
            <TermtypeSelection
              value={watch('term_type') || null}
              {...register('term_desc')}
              // type='TERM_TYPE'
              label='Term Type'
              onChange={(e, data) => {
                setValue('intervals_desc', null);
                setValue('intervals', null);
                setValue('intervals_other', null);
                setValue('term_type', data ? data.short_desc : null);
                setValue('term_desc', data ? data.short_desc : null);
              }}
              style={{ width: '100%' }}
            />
          </Box>
          <Box className='bd-dropdown-box'>
            <TermtypeIntervalSelection
              disabled={!watch('term_type')}
              value={watch('intervals') || null}
              {...register('intervals_desc')}
              type={watch('term_type') === 'Milestone' ? 'INTERVAL_MILE' : 'INTERVALS'}
              label='Intervals'
              onChange={(e, data) => {
                setValue('intervals', data ? data.short_desc : null, { shouldDirty: true });
                setValue('intervals_desc', data ? data.short_desc : null, { shouldDirty: true });
                if (data !== null && data.field_value !== 'Other Interval') {
                  setValue('intervals_other', '');
                }
              }}
              style={{ width: '100%' }}
            />
          </Box>
        </Box>
      </Box>
      <Box className='bd-container'>
        <Box className='bd-row'>
          <Box className='bd-dropdown-box'>
            <RegionSelection
              defaultValue={watch('billing_region') || currentValues?.billing_region || null}
              // {...register('billing_region')}
              onChange={(e, data) => {
                setValue('billing_region', data?.short_desc, { shouldDirty: true });
              }}
              label='Billing Region'
              required={true}
              style={{ width: '100%' }}
            />
          </Box>
          <Box className='bd-input-box'>
            {watch('intervals') === 'Other Interval' && (
              <CustomInputField
                defaultValue={watch('intervals_other') || ''}
                label={'Interval Other'}
                onChange={e => {
                  setValue('intervals_other', e.target.value, { shouldDirty: true });
                }}
                style={{ width: '100%' }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box className='bd-container'>
        <Box className='bd-row'>
          <Box className='bd-dropdown-box'>
            <FeetypeSelection
              defaultValue={watch('fee_type') || currentValues.fee_type || FEE_TYPES.ONE_THIRD}
              {...register('fee_type_desc')}
              type='FEE_TYPE'
              onChange={(e, data) => {
                setValue('fee_type', data ? data.short_desc : null);
                setValue('fee_type_desc', data ? data.short_desc : null);
                setValue('fee_percentage', !data || data.short_desc === FEE_TYPES.ONE_THIRD ? 33.33 : !data || data.short_desc === FEE_TYPES.FIXED_FEE ? 0 : null);
              }}
              label='Fee Type'
              style={{ width: '100%' }}
            />
          </Box>
          <Box className='bd-input-box'>
            <CustomInputField
              value={watch('fee_percentage') || ''}
              label={'Fee Percentage'}
              onChange={e => {
                setValue('fee_percentage', e.target.value, { shouldDirty: true });
              }}
              disabled={watch('fee_type') !== FEE_TYPES.OTHER}
              type='number'
              // inputProps={{ min: 0, max: 100 }}
              inputPropsMin={0}
              inputPropsMax={100}
              error={watch('fee_percentage') > 100}
              flag='true'
            />
          </Box>
        </Box>
      </Box>
      <Box className='bd-container'>
        <Box className='bd-row'>
          <Box className='bd-input-box'>
            <CustomInputField
              multiline
              rowsMax={3}
              defaultValue={currentValues?.billing_notes || null}
              label='Billing Notes'
              InputLabelProps={{ focused: true }}
              onChange={e => {
                setValue('billing_notes', e.target.value, { shouldDirty: true });
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

BillingForm.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  userRef: PropTypes.object,
  currentValues: PropTypes.object,
  isHide: PropTypes.bool,
  currencyDetails: PropTypes.object,
  isEditing: PropTypes.bool
};
export default BillingForm;
