import { Box, Grid, InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { formatDate } from '../../../utils/date';
import './index.scss';

const BasicDetailsView = ({ defaultValues }) => {
  console.log('**************defaultValues', defaultValues);
  return (
    <Box className='d-flex justify-content-center ' id='basic-details-view'>
      <Box sx={{ mx: 'auto' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 4, md: 12 }} className='flex'>
          <Grid item>
            <Box className='d-flex flex-column justify-content-end align-items-end'>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>Opportunity #</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>Company</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>Job Title</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>Location</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>Probability %</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>Service Offering</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>Competition</InputLabel>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box className='d-flex flex-column'>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>{defaultValues.job_number ? defaultValues.job_number : '--'}</InputLabel>
              </Box>
              <Box className='d-flex  mb-3 mt-3'>
                <InputLabel className='page-label'>{defaultValues?.ign_companies ? defaultValues?.ign_companies?.name : '--'}</InputLabel>
              </Box>
              <Box className='d-flex  mb-3 mt-3'>
                <InputLabel className='page-label'>{defaultValues.job_title || '--'}</InputLabel>
              </Box>
              <Box className='d-flex  mb-3 mt-3'>
                <InputLabel className='page-label'>{defaultValues?.country?.name || '--'}</InputLabel>
              </Box>
              <Box className='d-flex  mb-3 mt-3'>
                <InputLabel className='page-label'>{defaultValues.probability !== undefined ? (defaultValues.probability === 0 ? 0 : defaultValues.probability) : '--'}</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>{defaultValues.service_offering || defaultValues.brand || '--'}</InputLabel>
              </Box>
              <Box className='d-flex  mb-3 mt-3'>
                <InputLabel className='page-label'>{defaultValues.competition || '--'}</InputLabel>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box className='d-flex flex-column justify-content-end align-items-end'>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>Stage</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>Client contacts</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>Projected Start Date</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>Partners</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>BD Status</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>Geography</InputLabel>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box className='d-flex flex-column'>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>{defaultValues?.stage ? defaultValues?.stage : '--'}</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                {defaultValues?.client_team?.length > 0
                  ? defaultValues?.client_team?.map((contact, index) => (
                      <InputLabel className='page-label' component={''} key={index}>
                        {contact?.name || '--'} {index < defaultValues.client_team.length - 1 && ', '}
                      </InputLabel>
                    ))
                  : '--'}
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>{formatDate(defaultValues.projected_start_date) || '--'}</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label multiple-view-selection-list'>
                  {defaultValues.basic_partners?.length > 0 ? defaultValues.basic_partners.map((partnerDetails, index) => <p key={index}>{partnerDetails.name}</p>) : '--'}
                </InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>{defaultValues.bd_status || 'Target Identified'}</InputLabel>
              </Box>
              <Box className='d-flex mb-3 mt-3'>
                <InputLabel className='page-label'>{defaultValues.markets ? defaultValues.markets.market : '--'}</InputLabel>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

BasicDetailsView.propTypes = {
  defaultValues: PropTypes.object
};

export default BasicDetailsView;
