import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import WorkIcon from '@mui/icons-material/Work';
import { Box, Grid, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchPickLists } from '../../../actions';
import { ButtonDropDown } from '../../../components/common/ButtonDropdown';
import CustomButton from '../../../components/common/CustomButton';
import CustomOptions from '../../../components/common/CustomCheckbox';
import CustomModal from '../../../components/common/CustomModal';
import CloneProjectModal from '../../../components/common/CustomModal/ProjectCloneModal';
import { BillingInfoApi, CandidateStageSetupAPi, ProjectDataApi } from '../../../services/ApiService';
import { GET, POST, ROUTES } from '../../../services/constantService';
import { calculateNumberOfDays } from '../../../utils/date';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CompanyImageAvatar from '../CompanyImageAvatar';
import AssignTag from './AssignTag';
import ViewReportDrawer from './CandidatePipeline/ViewReportDrawer/index';
import { ProjectDataContext } from './Context';
import './index.scss';
import LinkExistingProject from './LinkExistingProject';

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  ['& .MuiTooltip-tooltip']: {
    backgroundColor: '#fffffF',
    color: '#007e6b',
    fontSize: '1rem',
    border: '0.2px solid black'
  }
}));

function HeaderOpened(props) {
  const dispatch = useDispatch();
  const bdStatusList = useSelector(state => state.commonReducer.bdStatus);
  const [bdStatusOptions, setBdStatusOptions] = useState([]);

  useEffect(() => {
    if (!bdStatusList) {
      dispatch(fetchPickLists('BD_STATUS', 'bdStatus'));
    } else {
      setBdStatusOptions(bdStatusList);
    }
  }, [bdStatusList, dispatch]);

  const tr = useTranslation();
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_ADDED_BY,
    TRANS_KEYS.ADD_PROJECT_SELECT_HIRING_MANAGER,
    TRANS_KEYS.SEARCH_ROAD_MAP,
    TRANS_KEYS.OPEN_BUTTON,
    TRANS_KEYS.NO_OF_DAYS,
    TRANS_KEYS.ADD_CANDIDATE,
    TRANS_KEYS.CONTACTS_INDUSTRY,
    TRANS_KEYS.CANDIDATES_IN_PIPELINE,
    TRANS_KEYS.BD_CONSULTANT,
    TRANS_KEYS.BD_PROBABILITY,
    TRANS_KEYS.BD_ESTIMATED_REVENUE,
    TRANS_KEYS.BILLING_STATUS,
    TRANS_KEYS.PROJECTS_SELECT_TAG,
    TRANS_KEYS.CONTACTS_TAGS
  ]);
  const { fetchProjectData, handleExpand, handleAddCandidate, isPublished, handlePublish, stages, handleStageChange = () => {}, handleBdStatusChange = () => {}, stage, bdStatus } = props;
  const { projectData, bgData, isBD } = useContext(ProjectDataContext);
  const [viewReport, setViewReport] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]); // will be a state for selected options for cloning project
  const [showExistingProject, setShowExistingProject] = useState(false);
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [createdProjectId, setCreatedProjectId] = useState(null);
  const [candidateStages, setCandidateStages] = useState([null]);

  const handleAddProject = key => {
    if (key === tr.t('project.linkExistingProject')) {
      handleLinkExistingProject();
    } else if (key === tr.t('project.createNewProject')) {
      setIsModalOpen(true);
    }
  };

  const handleLinkExistingProject = () => {
    setShowExistingProject(true);
  };
  const currencyCode = bgData?.bd_currency_values?.code || 'USD';
  const estimatedAmt = parseInt(bgData?.estimated_revenue);

  const handleCreateNewProject = async bdStatus => {
    try {
      /**
       * for creation => required fields are:
       *  - company name
       *  - job title
       *  - industry name
       *  - location
       */
      /** API TO CREATE PROJECT ===>>> STARTS */
      const createProjectPayload = {
        location_place_id: projectData?.location_place_id,
        location: projectData?.location,
        company_id: projectData?.company_id,
        industry_id: projectData?.industry_id,
        job_title: projectData?.job_title,
        bd_project_id: projectData.id,
        bd_currency: projectData?.currency,
        job_type: projectData?.job_type || '',
        max_compensation: projectData?.projectData || 0,
        max_experience: projectData?.max_experience || 0,
        min_compensation: projectData?.min_compensation || 0,
        min_experience: projectData?.min_experience || 0,
        pay_frequency: projectData?.pay_frequency || '',
        stage: 'Draft'
      };
      setIsLoading(true);
      const { data: response } = await ProjectDataApi(POST, '', createProjectPayload, '', '');
      /** API TO CREATE PROJECT ===>>> ENDS */

      /**
       * POST CREATION ADD API TO CLONE PREFERENCES
       */
      const createdProjectId = response.id;

      /** API TO CLONE OVERVIEW ===>>> STARTS */
      if (selectedOptions.includes('overview')) {
        await ProjectDataApi(
          POST,
          '',
          {
            projectId: createdProjectId,
            bdId: projectData.id
          },
          'copy-overview-from-bd'
        );
      }
      /** API TO CLONE OVERVIEW ===>>> ENDS */

      /** API TO CLONE BILLING INFO ===>>> STARTS */
      if (selectedOptions.includes('billing')) {
        await BillingInfoApi(
          POST,
          {
            projectId: createdProjectId,
            bdId: projectData.id
          },
          'replicate_revenues'
        );
      }
      /** API TO CLONE BILLING INFO ===>>> ENDS */

      /** API TO CLONE ROADMAP ===>>> STARTS */
      if (selectedOptions.includes('roadmap')) {
        await ProjectDataApi(
          POST,
          '',
          {
            projectId: createdProjectId,
            bdId: projectData.id
          },
          'copy-roadmap-from-bd'
        );
      }
      /** API TO CLONE ROADMAP ===>>> ENDS */

      /** API TO CLONE CANDIDATE PIPELINE ===>>> STARTS */
      if (selectedOptions.includes('candidatePipeline')) {
        await ProjectDataApi(
          POST,
          '',
          {
            projectId: createdProjectId,
            bdId: projectData.id
          },
          'copy-candidates-from-project'
        );
      }
      /** API TO CLONE CANDIDATE PIPELINE ===>>> ENDS */

      /** UPDATE BD STATUS */
      if (bdStatus) {
        await handleBdStatusChange(bdStatus);
      }

      // Update created project Id
      setCreatedProjectId(createdProjectId);
      setIsLoading(false);
      setIsSuccess(true);
    } catch (error) {
      setIsLoading(false);
      setIsSuccess(false);
    }
  };

  // navigate to newly created project
  const onProjectCloneSuccess = () => {
    // Open the newly created project in a new tab
    const path = ROUTES.vProject.replace(':id', createdProjectId);
    window.open(path, '_blank');
  };

  const leadConsultant = bgData?.partners?.find(partner => partner?.is_lead === true);
  const leadConsultantContactId = leadConsultant?.user?.user_contacts?.[0]?.id;

  const handleAssignTag = () => {
    setIsTagPopupOpen(true);
  };
  const maxVisibleTags = 1;

  const handleMoreActions = (val, e) => {
    if (val?.component?.key === 'publish_unpublish') {
      handlePublish(e);
    } else if (val === tr.t('project.cloneProject')) {
      setIsProjectModalOpen(true);
    }
  };
  const getCandidateStages = async () => {
    const { data: stagesRes } = await CandidateStageSetupAPi(GET, '/all');
    const stages = stagesRes.map(stage => stage.stage_name);
    setCandidateStages(stages);
  };
  useEffect(() => {
    getCandidateStages();
  }, [projectData]);

  return (
    <Grid
      container
      className='p-2'
      sx={{
        backgroundColor: 'titleBarBackground.main',
        width: '100%',
        borderRadius: '0 0 12px 12px',
        position: 'relative',
        boxShadow: '0px 3px 5px 0px rgba(224, 224, 224, 1)'
      }}
    >
      {!isBD && <ViewReportDrawer fromHeaderBar projectName={projectData?.job_title} isDrawerOpen={viewReport} setIsDrawerOpen={setViewReport} projectId={projectData?.id} />}

      <Grid container Item xs={1} direction='column' justifyContent='space-between' alignItems='center' sx={{ maxWidth: '90%' }}>
        <CompanyImageAvatar
          id={projectData?.ign_companies?.id}
          sx={{
            width: '80px',
            height: '80px',
            margin: 'auto',
            borderRadius: '50%'
          }}
        />

        {!isBD ? (
          <Select components={'div'} className='stage-drop-down' size='small' sx={{ maxWidth: '100px' }} value={stage ?? ''} onChange={e => handleStageChange(e.target.value)}>
            {stages
              .filter(stage => stage.name.toLowerCase() !== 'bd draft')
              .map(stage => (
                <MenuItem key={stage.id} value={stage.name}>
                  {stage.name}
                </MenuItem>
              ))}
          </Select>
        ) : (
          <Select components={'div'} className='stage-drop-down' size='small' sx={{ maxWidth: '90%' }} value={bdStatus ?? ''} onChange={e => handleBdStatusChange(e.target.value)}>
            {bdStatusOptions.map(bdStatusRecord => (
              <MenuItem key={bdStatusRecord.short_desc} value={bdStatusRecord.short_desc}>
                {bdStatusRecord.short_desc}
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>
      <Grid item xs={9} className='p-2'>
        <Stack direction={'row'} spacing={2} alignItems={'flex-end'}>
          <Stack alignItems={'center'} direction={'row'}>
            <Typography variant='h5' className='ml-1' sx={{ fontWeight: 600 }}>
              {projectData?.job_title}
            </Typography>
            {!!projectData?.job_number && (
              <Typography variant='caption' className='ml-2'>
                {projectData?.record_type} : {projectData?.job_number}
              </Typography>
            )}
          </Stack>

          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
            {/* <EditIcon className="p-1" />
            <DeleteIcon className="p-1" /> */}
            {/* <Typography variant="caption">
              <CheckCircleOutlineIcon className="p-1" />
              Added to client Suite
            </Typography> */}
          </Stack>
        </Stack>
        <Grid container paddingTop={1}>
          <Grid container Item xs={4} direction='column' justifyContent='flex-start' alignItems='flex-start'>
            <Link
              style={{
                color: 'black'
              }}
              target='_blank'
              to={`${ROUTES.allCompanies}/${projectData?.ign_companies?.id}`}
            >
              <WorkIcon className='p-1' />
              <Typography variant='caption' className='pe-1' sx={{ cursor: 'pointer' }}>
                {projectData?.ign_companies?.name}
              </Typography>
            </Link>
            <Box>
              <LocationOnIcon className='p-1' />
              {projectData?.location?.length > 20 ? (
                <Tooltip placement='top' title={projectData?.location}>
                  <Typography variant='caption'>{projectData?.location.slice(0, 20) + '...'}</Typography>
                </Tooltip>
              ) : (
                <Typography variant='caption'>{projectData?.location}</Typography>
              )}
            </Box>
            <Box>
              <TimerOutlinedIcon className='p-1' />
              <Typography variant='caption'>
                {t(`${TRANS_KEYS.NO_OF_DAYS}:key`)}{' '}
                <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
                  {calculateNumberOfDays(projectData?.created_at)}
                </Typography>
              </Typography>
            </Box>
          </Grid>
          <Grid container Item xs={4} direction={'column'} justifyContent={'flex-start'}>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant='caption'>{t(`${TRANS_KEYS.CONTACTS_ADDED_BY}:key`)}: </Typography>
              <Typography variant='caption' width={'bold'} className='ml-1' sx={{ textDecoration: 'underline' }}>
                {' ' + projectData?.creator?.name}
              </Typography>
            </Stack>
            {!isBD && (
              <Box>
                <Typography variant='caption'>
                  {t(`${TRANS_KEYS.CONTACTS_INDUSTRY}:key`)} :
                  <Typography component={'span'} variant='caption' sx={{ color: 'primary.main' }}>
                    {projectData?.ign_industries?.name}
                  </Typography>
                </Typography>
              </Box>
            )}
            <Box className='mb-2'>
              <Stack direction='row' flexWrap={'wrap'} spacing={1}>
                <Typography variant='caption'>{t(`${TRANS_KEYS.CONTACTS_TAGS}:key`)}:</Typography>
                <Box className='d-flex align-items-center justify-content-center'>
                  {projectData?.project_tags?.length !== 0 ? (
                    projectData?.project_tags?.slice(0, maxVisibleTags).map((tag, index) => (
                      <Box key={index} className={`tag-border tag${tag?.tag?.name}`} style={{ color: tag?.tag?.color }}>
                        <p className='fs-12 text-black mr-1'>{tag?.tag?.name}</p>
                      </Box>
                    ))
                  ) : (
                    <p className='fs-12 font-normal mr-1'>N/A</p>
                  )}

                  <CustomTooltip
                    title={
                      projectData?.project_tags?.slice(1)?.length !== 0 ? (
                        <Box display={'flex'} flexWrap={'wrap'} width={'200px'}>
                          {projectData?.project_tags?.slice(1)?.map((tag, index) => (
                            <Box key={index} className={`tag-border mr-2 tag${tag?.tag?.name}`} style={{ color: tag?.tag?.color }}>
                              <p style={{ fontSize: 12, fontWeight: 600 }}>{tag?.tag?.name}</p>
                            </Box>
                          ))}
                        </Box>
                      ) : (
                        ''
                      )
                    }
                    placement='top'
                  >
                    <p className='mr-2 cursor-pointer' style={{ fontSize: 12, fontWeight: 600, color: 'black' }}>
                      {projectData?.project_tags?.slice(1)?.length ? `+${projectData.project_tags?.length - 1}` : ''}
                    </p>
                  </CustomTooltip>

                  <Link component='button' onClick={handleAssignTag}>
                    <p style={{ fontSize: 12, fontWeight: 600, color: 'black' }}>+Tag</p>
                  </Link>
                </Box>
              </Stack>
              <AssignTag
                tagPopUp={projectData?.project_tags}
                projectId={projectData?.id}
                setIsPopupOpen={setIsTagPopupOpen}
                isPopupOpen={isTagPopupOpen}
                label={t(`${TRANS_KEYS.PROJECTS_SELECT_TAG}:key`)}
                getHeaderDetails={() => {}}
                setIsEdited={() => {}}
                fetchProjectData={fetchProjectData}
              />
            </Box>
          </Grid>
          <Grid container Item xs={4} direction='column' justifyContent='flex-start' alignItems='flex-start' gap={1}>
            {!isBD && (
              <Typography variant='caption'>
                {t(`${TRANS_KEYS.ADD_PROJECT_SELECT_HIRING_MANAGER}:key`)}:
                <Link
                  style={{
                    color: 'black'
                  }}
                  target='_blank'
                  to={`${ROUTES.allContactGrid}/${projectData?.hiring_manager?.[0]?.contact?.id}`}
                >
                  <Typography component={'span'} variant='caption' className='ml-1 cursor-pointer' sx={{ textDecoration: 'underline' }}>
                    {projectData?.hiring_manager?.[0]?.contact?.name}
                  </Typography>
                </Link>
              </Typography>
            )}
            {!isBD && projectData?.hiring_manager?.slice(1)?.length !== 0 && (
              <CustomTooltip
                title={
                  <Box display={'flex'} flexWrap={'wrap'} width={'200px'}>
                    {projectData?.hiring_manager?.slice(1)?.map(item => (
                      <Link
                        style={{
                          color: 'black'
                        }}
                        key={item?.contact?.id}
                        target='_blank'
                        to={`${ROUTES.allContactGrid}/${item?.contact?.id}`}
                      >
                        <p style={{ fontSize: 12, fontWeight: 600 }}>{item?.contact?.name}</p>
                      </Link>
                    ))}
                  </Box>
                }
                placement='top'
                arrow
              >
                <p className='mr-2 cursor-pointer' style={{ fontSize: 12, fontWeight: 600, color: 'black' }}>
                  {projectData?.hiring_manager?.slice(1)?.length ? `+${projectData.hiring_manager?.length - 1}` : ''}
                </p>
              </CustomTooltip>
            )}
            {isBD && leadConsultant && (
              <Box className='mb-2'>
                <Stack direction='row' flexWrap={'wrap'} spacing={1}>
                  <Typography variant='caption'>{t(`${TRANS_KEYS.BD_CONSULTANT}:key`)}:</Typography>
                  <Link
                    style={{
                      color: 'black',
                      textDecoration: leadConsultantContactId ? 'underline' : 'none',
                      fontSize: '0.8rem',
                      cursor: leadConsultantContactId ? 'pointer' : 'default'
                    }}
                    target={leadConsultantContactId ? '_blank' : '_self'}
                    to={leadConsultantContactId ? `${ROUTES.allContactGrid}/${leadConsultantContactId}` : '#'}
                  >
                    {leadConsultant?.user?.name}
                  </Link>
                </Stack>
              </Box>
            )}
            {!isBD && (
              <Box>
                <Typography variant='caption'>
                  {t(`${TRANS_KEYS.CANDIDATES_IN_PIPELINE}:key`)} :
                  <Typography component={'span'} variant='caption' sx={{ color: 'primary.main' }}>
                    {projectData?.candidate_count ?? 0}
                  </Typography>
                </Typography>
              </Box>
            )}
            {
              <Box>
                <Typography variant='caption'>
                  {t(`${TRANS_KEYS.BILLING_STATUS}:key`)} :
                  <Typography component={'span'} variant='caption' sx={{ color: 'primary.main' }}>
                    {` ${bgData?.billing_status_desc?.short_desc ?? ' -'}`}
                  </Typography>
                </Typography>
              </Box>
            }
            {isBD && (
              <Box>
                <Typography variant='caption'>
                  {t(`${TRANS_KEYS.BD_PROBABILITY}:key`)} :
                  <Typography component={'span'} variant='caption' sx={{ color: 'primary.main' }}>
                    {' '}
                    {projectData?.probability || '0%'}
                  </Typography>
                </Typography>
              </Box>
            )}
            <Box>
              <Typography variant='caption'>
                {t(`${TRANS_KEYS.BD_ESTIMATED_REVENUE}:key`)} :
                <Typography component={'span'} variant='caption' sx={{ color: 'primary.main' }}>
                  {' '}
                  {currencyCode} {estimatedAmt}
                </Typography>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={2} direction={'column'} justifyContent={'center'} alignItems={'flex-end'}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'flex-end'}>
          <CustomButton
            show={!isBD}
            variant='contained'
            buttonText={tr.t('reports.viewReports')}
            type={'primary'}
            onClick={() => {
              setViewReport(true);
            }}
          />

          <ButtonDropDown
            show={!isBD}
            iconRight={<ArrowDropDownIcon />}
            options={[
              tr.t('project.cloneProject'),
              {
                component: (
                  <Box key={'publish_unpublish'} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                    {isPublished ? tr.t('project.published') : tr.t('project.projectUnPublished')} <ArrowDropDownIcon />
                  </Box>
                ),
                close: false
              }
            ]}
            buttonText={tr.t('actions.moreActions')}
            className={'my-2'}
            onClick={handleMoreActions}
            MenuItemStyle={{
              borderBottom: '1px solid black',
              fontSize: '14px',
              fontWeight: 600
            }}
          />

          <ButtonDropDown
            show={!isBD}
            iconRight={<ArrowDropDownIcon />}
            variant='contained'
            options={['Quick add', 'Add from resume', 'Select existing contact']}
            buttonText={t(`${TRANS_KEYS.ADD_CANDIDATE}:key`)}
            onClick={handleAddCandidate}
            MenuItemStyle={{
              borderBottom: '1px solid black',
              fontSize: '14px',
              fontWeight: 600
            }}
          />

          <ButtonDropDown
            show={isBD}
            iconRight={<ArrowDropDownIcon />}
            variant='contained'
            options={[tr.t('project.createNewProject'), tr.t('project.linkExistingProject')]}
            buttonText={`${tr.t('actions.add')} ${tr.t('module.project')}`}
            onClick={handleAddProject}
          />
          {isModalOpen && isBD && (
            <CustomModal
              title='Create Project'
              isModalOpen={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
                setIsSuccess(false);
                setSelectedOptions([]);
              }}
              onSubmit={handleCreateNewProject}
              isSuccess={isSuccess}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              isLoading={isLoading}
              onSuccess={onProjectCloneSuccess}
              bdStatusOptions={bdStatusOptions}
            >
              <CustomOptions value='overview' label='Overview' />
              <CustomOptions value='billing' label='Billing' />
              <CustomOptions value='roadmap' label='Roadmap' />
              <CustomOptions value='candidatePipeline' label='Candidate Pipeline' />
            </CustomModal>
          )}
          {isProjectModalOpen && !isBD && (
            <CloneProjectModal
              title='Clone Project'
              open={isProjectModalOpen}
              onClose={() => setIsProjectModalOpen(false)}
              projectData={projectData}
              setCreatedProjectId={setCreatedProjectId}
              onSuccess={onProjectCloneSuccess}
              candidateStages={candidateStages}
            />
          )}

          <LinkExistingProject companyId={projectData?.company_id} bgId={bgData?.id} open={showExistingProject} onClose={() => setShowExistingProject(false)} />
        </Box>
      </Grid>

      <KeyboardArrowUpIcon
        sx={{
          position: 'absolute',
          bottom: '-10px',
          left: '48%',
          backgroundColor: 'primary.main',
          color: 'titleBarBackground.main',
          borderRadius: '50%',
          cursor: 'pointer'
        }}
        onClick={handleExpand}
      />
    </Grid>
  );
}

HeaderOpened.propTypes = {
  handleExpand: PropTypes.func,
  isPublished: PropTypes.bool,
  handlePublish: PropTypes.func,
  stages: PropTypes.array,
  handleStageChange: PropTypes.func,
  handleBdStatusChange: PropTypes.func,
  stage: PropTypes.string,
  bdStatus: PropTypes.string,
  handleAddCandidate: PropTypes.func,
  fetchProjectData: PropTypes.func
};

export default React.memo(HeaderOpened);
