//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { ThemeProvider } from '@mui/material/styles';
import moment from 'moment-timezone';
import { SnackbarProvider } from 'notistack';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Routers from './routes';

//-----------------------------------------------------------// Internal Imports // ------------------------------

/* import Header from "./components/Header";
import SideNav from "./components/SideNav"; */
import { CssBaseline } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TokenValidator from './Containers/Commons/TokenValidator';
import Loader from './components/common/Loader';
import SnackbarCloseButton from './components/common/SnackbarCloseButton/SnackbarCloseButton';
import PublicRouters from './publicRoutes';
import { getUserSelector } from './selectors';
import { IGNYTE_GLOBALS, PUBLIC_BASE_ROUTES } from './services/constantService';
import { useSocketHook } from './utils/Hooks/UseSocketHook';
import { useThemeHook } from './utils/Hooks/UseThemeHook';
import { UseUserSecurityHook } from './utils/Hooks/UseUserSecurityHook';
import { useWebWidgetHook } from './utils/Hooks/UseWebWidgetHook';

moment.tz.setDefault('Etc/UTC');

const App = () => {
  const userData = useSelector(getUserSelector);
  const { theme } = useThemeHook();
  const [opacityTheme, setOpacityTheme] = useState({});
  UseUserSecurityHook();
  useSocketHook();
  useWebWidgetHook();

  function hexToRgb(hex, opacity) {
    const parsedHex = hex.trim().replace('#', '');
    const bigint = parseInt(parsedHex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  useEffect(() => {
    const color = theme.palette.primary.main;

    const opacity5 = hexToRgb(color, 0.05);
    const opacity10 = hexToRgb(color, 0.1);
    const opacity20 = hexToRgb(color, 0.2);
    const opacity30 = hexToRgb(color, 0.3);
    const opacity40 = hexToRgb(color, 0.4);
    const opacity50 = hexToRgb(color, 0.5);

    setOpacityTheme({ opacity5, opacity10, opacity20, opacity30, opacity40, opacity50 });
  }, [theme]);
  // console.log({ main: theme.palette.primary.main });
  const pathName = '/' + window.location.pathname.split('/')[1];
  return (
    <>
      <Helmet>
        <title>Welcome - {IGNYTE_GLOBALS.BRAND}</title>
        <style>
          {`
          :root {
            --palette-primary-main:   ${theme.palette.primary.main};
            --palette-secondary-main: ${theme.palette.secondary.main};
            --palette-error-main:     ${theme.palette.error.main};
            --palette-heading-main:   ${theme.palette.heading.main};
            --palette-text-main:   ${theme.palette.text.main};
            --palette-bg-main:     ${theme.palette.background.main};
            --palette-title-bar-main:   ${theme.palette.titleBarBackground.main};
            --palette-primary-opacity-5 : ${opacityTheme?.opacity5 || '#000000'};
            --palette-primary-opacity-10 : ${opacityTheme?.opacity10 || '#000000'};
            --palette-primary-opacity-20 : ${opacityTheme?.opacity20 || '#000000'};
            --palette-primary-opacity-30 : ${opacityTheme?.opacity30 || '#000000'};
            --palette-primary-opacity-40 : ${opacityTheme?.opacity40 || '#000000'};
            --palette-primary-opacity-50: ${opacityTheme?.opacity50 || '#000000'}
          }
        `}
        </style>
      </Helmet>
      {userData ? (
        <Router>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
              <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }} action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
                <div className='main-wrapper d-flex flex-column h-100'>
                  {/* <Header />
                  <div className="content-wrapper d-flex w-100">
                    <SideNav />
                    <div className="main-content"> */}
                  <Routers />
                  {/* </div>
                  </div> */}
                </div>
              </SnackbarProvider>
              {/* </MuiPickersUtilsProvider> */}
            </LocalizationProvider>
          </ThemeProvider>
        </Router>
      ) : pathName && Object.values(PUBLIC_BASE_ROUTES).includes(pathName) ? (
        <Router>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
            <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }} action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
              <div className='main-wrapper d-flex flex-column h-100'>
                <PublicRouters />
              </div>
            </SnackbarProvider>
            {/* </MuiPickersUtilsProvider> */}
          </ThemeProvider>
        </Router>
      ) : (
        <div>
          <TokenValidator />
          <Loader show={true} />
        </div>
      )}
    </>
  );
};
export default App;
