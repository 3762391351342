import { Box, Divider, Grid, Skeleton, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AllDocumentsApi, deleteLoeById, fetchLOETasksList, getLoeDocumentByInstanceId, LOEGetAll, updateLOE } from '../../../services/ApiService.js';
import * as AttachmentsService from '../../../services/AttachmentsService.js';
import { IGN_API, POST } from '../../../services/constantService.js';
import AddLoePopup from './AddLoePopup.jsx';
import { LOEDetails, LOEHeader, LOEList } from './Index.jsx';
function LOEPage() {
  const [search, setSearch] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [selectedLOE, setSelectedLOE] = React.useState({
    id: '',
    name: '',
    list: []
  });
  const [editPopup, setEditPopup] = React.useState(false);
  const [values, setValues] = useState({});
  const [list, setList] = React.useState([
    // {
    //   id: 1,
    //   name: 'LOE 1',
    //   consultantEmail: 'jondoe@gmail.com',
    //   consultantName: 'John Doe',
    //   clientName: 'Jane Doe',
    //   clientEmail: 'jondoe@gmail.com',
    //   createdBy: 'Jane Doe',
    //   time: '2021-10-10T00:00:00.000Z',
    //   status: 'IN_PROGRESS',
    //   onDelete: () => {},
    //   downloadLOE: () => {},
    //   onEdit: () => {},
    //   onAdd: () => {},
    //   setSelected: () => {},
    //   selected: true
    // }
  ]);
  const [instanceId, setInstanceId] = React.useState(null);
  const [sort, setSort] = React.useState(''); // ['IN_PROGRESS', 'COMPLETED', 'CANCELED']
  const { id } = useParams();
  const [page, setPage] = React.useState(0);
  const { data, isLoading, isRefetching, error, refetch } = useQuery({
    queryKey: [id],
    queryFn: async () => {
      const res = await LOEGetAll(id);
      if (res?.data && res?.data.length > 0) {
        setInstanceId(res?.data[0]?.instance_id || null);
      }
      setList(res.data);
    }
  });

  const {
    data: taskData,
    isLoading: activityLoading,
    isRefetching: activityLoadingRefetch
  } = useQuery({
    queryKey: [instanceId],
    queryFn: async () => {
      if (!instanceId && typeof instanceId !== 'string') return;
      const res = await fetchLOETasksList(instanceId);
      const activityList = res?.data?.tasks?.map(item => {
        return {
          id: item.id,
          name: item.label,
          time: item.startDate
        };
      });
      if (res?.data?.refetch) {
        refetch();
      }
      setSelectedLOE({
        ...selectedLOE,
        list: activityList
      });
      return res;
    },

    enabled: !!instanceId
  });
  // post request to get LOE Document
  const downloadLOEDocument = async id => {
    // const token = await getAccessToken();
    try {
      let attachmentId = id;

      // const sub_route = `/documents/${attachmentId}/download`
      const url = await AttachmentsService.downloadProjectDocuments(attachmentId);

      // const url = `http://localhost:8080/api/v1/project/documents/${attachmentId}/download?token=${token}`
      if (url) {
        const origin = window.location.origin;
        const subdomain = origin.split('.')[0];
        const restOfDomain = origin.substring(origin.indexOf('.') + 1);
        window.open(`${subdomain}.app.${restOfDomain}/${url}`);
        // window.open('http://localhost:8080/api/v1/project/documents/' + attachmentId + '/download' + '?token=null');
      } else {
        throw new Error(Promise.reject());
      }
    } catch (e) {
      console.log('Error found in downloadAttachment::', e);
      throw new Error(e);
    }
  };
  const onDeleteLOE = async id => {
    enqueueSnackbar('LOE Deleted Successfully', { variant: 'success' });
    await deleteLoeById(id);
    refetch();
  };
  const downloadLOE = async (instanceId, loe) => {
    try {
      enqueueSnackbar('Please wait', { variant: 'info' });
      if (!loe?.document_id) {
        const documents = await getLoeDocumentByInstanceId(instanceId);

        if (documents?.data) {
          await onAdd(loe?.id, { instance_id: instanceId, document_id: documents?.id?.id });
          const blob = new Blob([documents.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `document-${instanceId}.pdf`;
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } else {
        await downloadLOEDocument(loe?.document_id);
      }
      enqueueSnackbar(`LOE Downloaded Successfully for ${id} `, { variant: 'success' });
    } catch (e) {
      throw new Error(e);
    }
  };

  const onEdit = (id = '', values = {}) => {
    setValues({
      id: id,
      instance_id: values.instance_id,
      consultantName: {
        value: values.consultant_name || '',
        label: values.consultant_name || ''
      },
      consultantEmail: values.consultant_email,

      consultationTitle: values.consultation_title,

      // consultationEngagementCountry: values.consultation_engagement_country,
      clientEmail: values.client_email
        ? { value: values.client_email, label: values.client_email }
        : {
            value: '',
            label: ''
          },
      clientPrefix: { value: values.client_prefix, label: values.client_prefix },
      clientFirstName: values.client_first_name,
      clientLastName: values.client_last_name,
      clientTitle: values.client_title,
      clientCompanyName: values.client_company,
      initialFeeAmount: values.initial_fee,
      progressFeeAmount: values.progress_fee,
      status: values.status
    });
    setEditPopup(true);
    setPage(1);
    setOpen(true);
  };
  const onAdd = async (loeId, values) => {
    if (!values?.document_id) {
      enqueueSnackbar('Please Wait', { variant: 'info' });
      const documents = await getLoeDocumentByInstanceId(values?.instance_id);
      let bodyFormData = new FormData();
      bodyFormData.append('file_name', `loeDocument-${values?.instance_id}.pdf`);
      bodyFormData.append('doctype_code', 'other');
      bodyFormData.append('file', documents?.data);
      // bodyFormData.append('document_visibilities', '[false]');
      const sub_route = `${IGN_API.project}/${id}/documents`;
      const { status, data } = await AllDocumentsApi(POST, sub_route, bodyFormData);
      // processUpload(status, data, document.file.name);
      console.log('data', status, data?.id?.id);
      await updateLOE(loeId, { document_id: data?.id?.id });
      enqueueSnackbar(`LOE Added Successfully for ${id}`, { variant: 'success' });
    } else {
      enqueueSnackbar('Document already exists', { variant: 'info' });
      return;
    }
  };
  const setSelected = item => {
    if (activityLoading) return;
    setSelectedLOE({
      ...selectedLOE,
      id: item.instance_id || item.id
    });
    console.log('item', item);
    setInstanceId(item.instance_id || null);
  };

  return (
    <Box height={'calc(100vh - 350px)'} width={'100%'}>
      <Grid container spacing={2} height={'100%'} width={'100%'} justifyContent='space-between'>
        <Grid item xs={12}>
          <LOEHeader search={search} setSearch={setSearch} sort={sort} setSort={setSort} open={open} setOpen={setOpen} refetch={refetch} />
          <Divider
            sx={{
              mt: '12px',
              borderColor: '#e0e0e0'
            }}
          />
        </Grid>
        <Grid item xs={7} height={'100%'} width={'100%'}>
          {isLoading || isRefetching ? (
            <Stack gap={'12px'} alignItems={'center'}>
              <Skeleton
                variant='rectangular'
                width={'90%'}
                height={'140px'}
                sx={{
                  borderRadius: '8px'
                }}
              />
            </Stack>
          ) : (
            <LOEList
              list={list}
              onDeleteLOE={onDeleteLOE}
              openCreateLOE={open}
              setOpenCreateLOE={setOpen}
              selectedLOE={selectedLOE}
              setSelected={setSelected}
              downloadLOE={downloadLOE}
              onEdit={onEdit}
              onAdd={onAdd}
            />
          )}
        </Grid>
        <Grid item xs={5} width={'100%'} height={'100%'}>
          {activityLoading || activityLoadingRefetch ? (
            <Skeleton
              variant='rectangular'
              width={'100%'}
              height={'100%'}
              sx={{
                borderRadius: '8px'
              }}
            />
          ) : (
            <LOEDetails selectedLOE={selectedLOE} />
          )}
        </Grid>
        <AddLoePopup open={open} setOpen={setOpen} refetch={refetch} edit={editPopup} setEditPopup={setEditPopup} editValues={values} setPage={setPage} page={page} setValues={setValues} />
      </Grid>
    </Box>
  );
}

export default LOEPage;
