//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { Button, FormControl, Input, InputLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

//-----------------------------------------------------------// Internal Imports // ------------------------------

// import UserSelection from "../../components/UserSelection";
import TypeSelection from '../../components/TypeSelection';
import { getUserSelector } from '../../selectors';
import SavedQuerySelection from '../Contacts/components/SavedQueries';
import SaveQueryDialog from '../Contacts/components/SaveQueryDialog';
import { startAdornment } from './utils';
//import GenderSelection from "../../components/GenderSelection";
// import SavedQueriesSelection from "../Companies/components/SavedQueriesSelection";
// import { getUserSelector } from "../../selectors";
// import { useSelector } from "react-redux";

export default function WorkbenchQuery(props) {
  const { handleQuery, type /* option */ } = props;
  const { register, unregister, handleSubmit, setValue, reset, watch } = useForm();
  const [open, setOpen] = React.useState(false);
  const [queryData, setQueryData] = useState({});
  const userData = useSelector(getUserSelector);
  const handleClose = () => {
    setOpen(false);
    setQueryData({});
  };

  const handleSaveQuery = data => {
    setQueryData(data);
    setOpen(true);
  };

  const handleSaveQueryChange = (e, data) => {
    if (data) {
      reset({ ...data.query });
      props.handleQuery({ ...data.query });
    }
  };
  let value, disable;
  disable = type.includes('contacts') || type.includes('searches') ? true : false;
  if (disable) {
    if (type.includes('contacts')) {
      value = 'Contacts';
    } else if (type.includes('searches')) {
      value = 'Searches';
    }
  } else {
    value = watch('type');
  }

  useEffect(() => {
    // register("users");
    register('fromCreate');
    register('toCreate');
    register('type');
    return () => {
      unregister('fromCreate');
      unregister('toCreate');
      unregister('type');
    };
  }, [unregister, register]);

  const advanceHandler = () => {
    props.pullData();
  };

  return (
    <div className='query'>
      <SaveQueryDialog type='workbenches' open={open} queryData={queryData} userId={userData?.id} handleClose={handleClose} />
      <div className='d-flex align-items-center'>
        <FormControl className='query-input'>
          <InputLabel focused htmlFor='contact'>
            WORKBENCH NAME
          </InputLabel>
          <Input {...register('name')} name='name' placeholder='Search' startAdornment={startAdornment} />
        </FormControl>
        {/* <UserSelection
          multiple
          option={option}
          value={watch("users")}
          className="query-input"
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label="USERS"
          placeholder="Select"
          onChange={(e, data) => {
            setValue("users", data);
          }}
        /> */}
        <FormControl className='query-input'>
          <InputLabel focused htmlFor='contact'>
            DESCRIPTION
          </InputLabel>
          <Input {...register('description')} name='description' placeholder='Search' startAdornment={startAdornment} />
        </FormControl>
        <div className='d-flex flex-column date-range query-input'>
          <InputLabel className='range-label' focused shrink={true} htmlFor='date-picker-inline'>
            CREATED DATE RANGE
          </InputLabel>
          <div className='d-flex align-items-center '>
            {/* <KeyboardDatePicker
              //disableToolbar
              autoOk
              variant='inline'
              format='dd/MM/yyyy'
              margin='normal'
              placeholder='From date'
              maxDate={watch('toCreate')}
              value={watch('fromCreate') || null}
              onChange={date => {
                setValue('fromCreate', date);
              }}
            /> */}
            <Typography className='to-label'>to</Typography>
            {/* <KeyboardDatePicker
              //disableToolbar
              autoOk
              placeholder='End date'
              variant='inline'
              format='dd/MM/yyyy'
              margin='normal'
              minDate={watch('fromCreate')}
              value={watch('toCreate') || null}
              onChange={date => {
                setValue('toCreate', date);
              }}
            /> */}
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-start'>
        <TypeSelection
          // multiple
          value={value}
          disabled={disable}
          className='query-input'
          InputLabelProps={{ focused: true, shrink: true }}
          // filterSelectedOptions={true}
          label='TYPE'
          placeholder='Select'
          onChange={(e, data) => {
            setValue('type', data);
          }}
        />
        {/* <GenderSelection 
          value={watch('gender')}
          className="query-input"
          InputLabelProps={{ focused: true, shrink: true }}
          // filterSelectedOptions={true}
          label={"Gender"}
          placeholder={"Select"}
          onChange={(e, data) => {
            setValue("gender", data);
          }}
        /> */}
      </div>
      <div className='d-flex align-items-center second-row  mt-3'>
        <Button className='mr-3' variant='outlined' color='primary' onClick={advanceHandler}>
          Switch to Advanced Query
        </Button>
        <div style={{ position: 'absolute', right: '80px' }}>
          <Button
            className='query-button'
            variant='contained'
            color='primary'
            /* style={{ width: 100, marginLeft: 'auto' }} */
            onClick={handleSubmit(handleQuery)}
          >
            Search
          </Button>
          <Button className={'save-query'} variant={'outlined'} color={'primary'} onClick={handleSubmit(handleSaveQuery)}>
            Save Query
          </Button>
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mt-4'>
        <SavedQuerySelection
          onChange={handleSaveQueryChange}
          refreshQueries={open}
          className='saved-queries'
          InputLabelProps={{ focused: true }}
          userId={userData?.id}
          disableClearable
          type='workbenches'
          /* style={{ marginLeft: 'auto', justifySelf: 'end' }} */
          style={{ marginLeft: 'auto', justifySelf: 'end' }}
        />
      </div>
    </div>
  );
}

WorkbenchQuery.propTypes = {
  handleQuery: PropTypes.func,
  type: PropTypes.string,
  pullData: PropTypes.func,
  option: PropTypes.array
};
