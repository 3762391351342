import { AgGridReact } from 'ag-grid-react';
import proptype from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../services/constantService';
import ExportCSV from '../../../utils/ExportCSV';
import GridHeaderMenu from '../../../utils/GridOptions';
import attachEventListenersToGrid from '../../../utils/SaveGridSetting';
import ColumnFilter from '../../Commons/ColumnFilter';
import { defaultColumns } from '../../Companies/Utils';
import CustomHeader from './CustomHeader';
let columnApi;
const TableView = ({ projectGridColumn, projectGridData, sx, loading, sortModel, gridState, filterModel }) => {
  console.log('filterModel', filterModel);
  const [isGridReady, setIsGridReady] = useState(false);
  const navigate = useNavigate();
  const homeGridRef = useRef();
  const csvExportRef = useRef();
  const onClickGridProject = project => {
    const { id, candidate_count } = project;

    let pathWithTab = ROUTES.viewProjectWithDeepLink;
    pathWithTab = pathWithTab.replace(':id', id);
    if (candidate_count > 0) {
      pathWithTab = pathWithTab.replace(':parentTab', 'Candidate Pipeline');
      pathWithTab = pathWithTab.replace(':subTab', 'null');
    } else {
      pathWithTab = pathWithTab.replace(':parentTab', 'Overview');
      pathWithTab = pathWithTab.replace(':subTab', 'Details');
    }
    navigate(pathWithTab);
  };
  const columns = useMemo(() => {
    let gridDefColumns = Array.isArray(gridState) && gridState.length ? gridState : projectGridColumn;
    return gridDefColumns.map((col, index) => {
      if (col.field === 'job_number') {
        return {
          ...col,
          cellRenderer: params => {
            const a = document.createElement('a');
            a.textContent = params.value;
            a.style.textDecoration = 'underline';
            a.onclick = () => {
              onClickGridProject(params?.data);
            };
            return a;
          }
        };
      } else {
        return {
          ...col,
          cellRenderer: params => {
            const span = document.createElement('span');
            span.style.whiteSpace = 'nowrap'; // Ensures single line
            span.style.overflow = 'hidden'; // Hides overflow
            span.style.textOverflow = 'ellipsis'; // Adds ellipses
            span.style.display = 'inline-block'; // Required for ellipses to work
            span.textContent = params.value;
            return span;
          }
        };
      }
    });
  }, [projectGridColumn, gridState, navigate]);

  const data = useMemo(() => projectGridData, [projectGridData, loading]);
  const isRowSelectable = useMemo(() => {
    return params => (params.data ? params.data.stage === 'Open' : false);
  }, []);

  useEffect(() => {
    if (isGridReady && gridState.length && homeGridRef.current) {
      gridState.forEach(col => {
        homeGridRef.current.columnApi?.setColumnVisible(col.colId, col.visible);
      });
    }
  }, [gridState]);

  useEffect(() => {
    if (!isGridReady) return;
    if (sortModel.length) {
      homeGridRef.current?.api.setSortModel(sortModel);
      homeGridRef.current.api.onSortChanged();
    }
    if (Object.keys(filterModel).length) {
      homeGridRef.current?.api.setFilterModel(filterModel);
      homeGridRef.current.api.onFilterChanged();
    }
  }, [filterModel, sortModel, isGridReady]);

  const onFirstDataRendered = useCallback(() => {
    if (homeGridRef.current) {
      const gridApi = homeGridRef.current.api;

      if (sortModel.length) {
        gridApi.setSortModel(sortModel);
      }

      if (Object.keys(filterModel).length) {
        gridApi.setFilterModel(filterModel);
      }
    }
  }, [filterModel, sortModel]);

  const optimizedFun = useCallback((param, type) => {
    attachEventListenersToGrid(param, type);
  }, []);

  const onGridReady = params => {
    columnApi = params.columnApi;
    params.columnApi.autoSizeColumns();
    homeGridRef.current = params;
    optimizedFun(params, 'homeGrid');
    // loadColumnStateFromLocalStorage("contactColumns", { columnApi, gridApi });
    // params.columnApi?.getAllColumns().map(col => {
    //   params.columnApi?.setColumnVisible(col.colId, col?.colDef?.visible);
    // });
    setIsGridReady(true);
  };

  const exportCSVColumns = [
    { key: 'job_number', label: 'Project ID' },
    { key: 'job_title', label: 'Job Title' },
    { key: 'ign_companies.name', label: 'Company' },
    { key: 'creator.name', label: 'Added By' },
    { key: 'stage', label: 'Stage' },
    { key: 'location', label: 'Location' },
    { key: 'ign_industries.name', label: 'Industry' },
    { key: 'service_offering', label: 'Service Offering' },
    { key: 'candidate_count', label: 'Candidates' },
    { key: 'probability', label: 'Probability' },
    { key: 'min_experience', label: 'Minimum Experience' },
    { key: 'max_experience', label: 'Maximum Experience' },
    { key: 'min_compensation', label: 'Minimum Compensation' },
    { key: 'max_compensation', label: 'Maximum Compensation' },
    { key: 'fee_type', label: 'Fee Type' },
    { key: 'fee_percentage', label: 'Fee Percentage' },
    { key: 'term_type', label: 'Term Type' }
  ];
  return (
    <>
      <div style={{ position: 'relative', top: '8px' }} className='d-flex justify-content-end align-items-center mt-2'>
        <ExportCSV ref={csvExportRef} columns={exportCSVColumns} data={data} fileName='Projects.csv' />
        <GridHeaderMenu onExport={() => csvExportRef.current.exportCSV()} gridRef={homeGridRef.current} />
      </div>
      <div id='myGrid' className='ag-theme-alpine header-column-filter'>
        <AgGridReact
          columnDefs={columns}
          rowData={data}
          suppressRowClickSelection={true}
          isRowSelectable={isRowSelectable}
          paginationPageSize={10}
          rowSelection='multiple'
          enableRangeSelection={true}
          defaultColDef={{
            resizable: true,
            filter: true,
            sortable: true
          }}
          headerComponentFramework={CustomHeader}
          {...sx}
          columnMenu={true}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
        />
        {isGridReady && (
          <ColumnFilter
            columnApi={columnApi}
            defaultColumns={defaultColumns}
            //showFilterCount={showFilterCount}
            //filterModel={filterModel}
          />
        )}
      </div>
    </>
  );
};

TableView.propTypes = {
  projectGridColumn: proptype.array,
  projectGridData: proptype.array,
  sx: proptype.object,
  loading: proptype.bool,
  sortModel: proptype.array,
  gridState: proptype.array,
  filterModel: proptype.object
};

export default TableView;
