//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { Checkbox, FormControl, FormControlLabel, RadioGroup, TextField, ToggleButtonGroup } from '@mui/material';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import CurrencySelection from '../../CurrencySelection';
import RightSideNav from '../../RightSideNav';
import InputField from '../../common/InputField';
import RecentSearchesActivities from '../RecentSearchesActivities';
// import { useStyles } from '../../AddContact/Components/Compensation/index.js'
import { DatePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { currencyValueSetter, isCurrency, separator } from '../../../Containers/Commons/Utils';
import { fetchCurrencyDetails } from '../../../actions';
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR } from '../../../services/constantService';
import { formatDate } from '../../../utils/date';
export default function CompensationView(props) {
  const { contact, renderLabel, renderActions, editingField, register, watch, setValue } = props;
  const dispatch = useDispatch();
  const currencyDetails = useSelector(state => (state.commonReducer[watch('currency_iso_code')] ? state.commonReducer[watch('currency_iso_code')] : {}));

  useEffect(() => {
    register('compensation_expectation');
    register('currency_iso_code');
    register('base_salary');
    register('previous_compensation');
    register('target_bonus_value');
    register('actual_bonus_value');
    register('bonus_payout_date');
    register('compensation_updated_date');
  }, [register]);

  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(watch('currency_iso_code'), dispatch);
      } catch (e) {
        console.log('Error found in currencyHandler::', e);
      }
    };
    if (watch('currency_iso_code') && Object.keys(currencyDetails)?.length === 0) {
      currencyHandler();
    }
  }, [watch('currency_iso_code')]);

  const FormElement = (field, editElement) => {
    const { id, label, type } = field;
    return (
      <div className='contact-details'>
        {renderLabel(label, id)}
        {editingField === id ? (
          <div className='d-flex'>
            {editElement ? (
              editElement
            ) : type === 'date' ? (
              getDatePicker(id, label)
            ) : id === 'compensation_expectation' || id === 'previous_compensation' || id === 'base_salary' || id === 'target_bonus_value' || id === 'actual_bonus_value' ? (
              <NumericFormat
                customInput={TextField}
                // style={{
                //   border: 'none',
                //   borderBottom: '2px solid #a3a3a3',
                //   width: '125px'
                // }}
                value={watch(id)}
                thousandSeparator={
                  isCurrency(id, id === 'actual_bonus_value' ? watch('actual_bonus_type') : watch('target_bonus_type')) ? separator(watch('currency_iso_code'), THOUSAND_SEPARATOR) : false
                }
                decimalSeparator={separator(watch('currency_iso_code'), DECIMAL_SEPARATOR)}
                decimalScale={2}
                isNumericString={true}
                prefix={
                  (isCurrency(id, id === 'actual_bonus_value' ? watch('actual_bonus_type') : watch('target_bonus_type')) ? true : false) && currencyDetails
                    ? currencyDetails.prefix
                      ? currencyDetails.currency_icon
                      : ''
                    : ''
                }
                suffix={
                  (isCurrency(id, id === 'actual_bonus_value' ? watch('actual_bonus_type') : watch('target_bonus_type')) ? true : false) && currencyDetails
                    ? currencyDetails.prefix === false
                      ? currencyDetails.currency_icon
                      : ''
                    : ''
                }
                onValueChange={values => {
                  const { value } = values;
                  setValue(id, value);
                }}
              />
            ) : (
              <InputField {...register(id)} className='input-form-field input-field-old' type={type} InputLabelProps={{ focused: true }} name={id} />
            )}
            {renderActions(id)}
          </div>
        ) : id === 'compensation_expectation' || id === 'previous_compensation' || id === 'base_salary' || id === 'target_bonus_value' || id === 'actual_bonus_value' ? (
          <NumericFormat
            style={{ border: 'none' }}
            value={watch(id) ? watch(id) : '--'}
            displayType='text'
            thousandSeparator={isCurrency(id, id === 'actual_bonus_value' ? watch('actual_bonus_type') : watch('target_bonus_type')) ? true : false}
            decimalSeparator='.'
            prefix={
              (isCurrency(id, id === 'actual_bonus_value' ? watch('actual_bonus_type') : watch('target_bonus_type')) ? true : false) && currencyDetails
                ? currencyDetails.prefix
                  ? currencyDetails.currency_icon
                  : ''
                : ''
            }
            suffix={
              (isCurrency(id, id === 'actual_bonus_value' ? watch('actual_bonus_type') : watch('target_bonus_type')) ? true : false) && currencyDetails
                ? currencyDetails.prefix === false
                  ? currencyDetails.currency_icon
                  : ''
                : ''
            }
            renderText={data => {
              const value = currencyValueSetter(data, watch('currency_iso_code'));
              return value ? value : '--';
            }}
            onValueChange={values => {
              const { value } = values;
              setValue(id, value);
            }}
          />
        ) : (
          <div className='contact-view-value'>{contact[id] ? (id === 'compensation_updated_date' || id === 'bonus_payout_date' ? formatDate(contact[id], 'DD/MM/YYYY') : contact[id]) : '--'}</div>
        )}
      </div>
    );
  };

  FormElement.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string
  };

  const CheckedElement = ({ id, label }) => {
    return (
      <div className='contact-details'>
        {renderLabel(label, id)}
        {editingField === id ? (
          <div className='d-flex'>
            <FormControl component='fieldset' className='w-100'>
              <RadioGroup row aria-label={id} name={id} className='radio-group'>
                <FormControlLabel value='true' control={<Checkbox defaultChecked={watch(id) || false} name={id} {...register(id)} />} label={watch(id) ? 'Yes' : 'No'} />
              </RadioGroup>
            </FormControl>
            {renderActions(id)}
          </div>
        ) : (
          <FormControlLabel control={<Checkbox disableRipple={true} style={{ paddingTop: 0, paddingBottom: 0 }} checked={contact[id] || false} color='primary' />} label={watch(id) ? 'Yes' : 'No'} />
        )}
      </div>
    );
  };

  CheckedElement.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string
  };

  const getDatePicker = (id, label) => (
    <DatePicker
      className='input-form-field input-field-old'
      disableToolbar
      {...register(id)}
      name={id}
      autoOk
      variant='inline'
      format='DD/MM/YYYY'
      margin='normal'
      value={formatDate(watch(id)) || null}
      onChange={date => {
        setValue(id, formatDate(date));
      }}
      InputLabelProps={{ focused: true }}
      placeholder={label}
    />
  );

  return (
    <div className='d-flex contact-view'>
      <div className='d-flex tab-view-content'>
        <div className='section-container'>
          <div className='section-header'>Compensation</div>
          <div className='d-flex contact-details-row'>
            {FormElement(
              { id: 'currency_iso_code', label: 'Currency Code' },
              <CurrencySelection
                value={watch('currency_iso_code') || null}
                className='input-form-field input-field-old'
                InputLabelProps={{ focused: true }}
                label=''
                onChange={(e, data) => {
                  setValue('currency_iso_code', data);
                }}
              />
            )}
            {FormElement({ id: 'compensation_expectation', label: 'Compensation Expectation' })}
          </div>
          <div className='d-flex contact-details-row'>{FormElement({ id: 'base_salary', label: 'Base Salary', type: 'number' })}</div>
          <div className='d-flex contact-details-row'>
            {FormElement(
              { id: 'target_bonus_type', label: 'Target Bonus Type' },
              <ToggleButtonGroup
                {...register('target_bonus_type')}
                exclusive
                value={watch('target_bonus_type')}
                onChange={(e, data) => {
                  setValue('target_bonus_type', data);
                }}
                aria-label='target_bonus_type'
              >
                {/* <ToggleButton classes={useStyles()} value="CURRENCY" aria-label="Currency">
                  <span>{watch('currency_iso_code') ? currencyDetails?.currency_icon : '$'}</span>
                </ToggleButton>
                <ToggleButton classes={useStyles()} value="PERCENTAGE" aria-label="Percentage">
                  <span>%</span>
                </ToggleButton> */}
              </ToggleButtonGroup>
            )}
            {FormElement({ id: 'target_bonus_value', label: 'Target Bonus', type: 'number' })}
          </div>
          <div className='d-flex contact-details-row'>
            {FormElement(
              { id: 'actual_bonus_type', label: 'Actual Bonus Type' },
              <ToggleButtonGroup
                {...register('actual_bonus_type')}
                exclusive
                value={watch('actual_bonus_type')}
                onChange={(e, data) => {
                  setValue('actual_bonus_type', data);
                }}
                aria-label='actual_bonus_type'
              >
                {/* <ToggleButton classes={useStyles()} value="CURRENCY" aria-label="Currency">
                  <span>{watch('currency_iso_code') ? currencyDetails?.currency_icon : '$'}</span>
                </ToggleButton>
                <ToggleButton classes={useStyles()} value="PERCENTAGE" aria-label="Percentage">
                  <span>%</span>
                </ToggleButton> */}
              </ToggleButtonGroup>
            )}
            {FormElement({ id: 'actual_bonus_value', label: 'Actual Bonus Value', type: 'number' })}
          </div>
          <div className='d-flex contact-details-row'>
            {FormElement({ id: 'equity', label: 'LTI', type: 'text' })}
            {FormElement({ id: 'bonus_payout_date', label: 'Bonus Payout Date', type: 'date' })}
          </div>
          <div className='d-flex contact-details-row'>
            {FormElement({ id: 'compensation_updated_date', label: 'Compensation Updated Date', type: 'date' })}
            {FormElement({ id: 'compensation_notes', label: 'Compensation Notes' })}
          </div>
          <div className='d-flex contact-details-row'>{FormElement({ id: 'previous_compensation', label: 'Previous Compensation' })}</div>
          <div className='d-flex contact-details-row'>
            {CheckedElement({ id: 'compensation_release', label: 'Compensation Release' })}
            {CheckedElement({ id: 'is_compensation_info_available', label: 'Compensation Info Available' })}
          </div>
          <div className='d-flex contact-details-row w-50'>{CheckedElement({ id: 'hide_compensation_clientsuite', label: 'Hide Compensation in ClientSuite' })}</div>
        </div>
      </div>
      <div className='tab-view-right-nav'>
        <RightSideNav navItems={['Compensation']} />
        <RecentSearchesActivities contact={contact} />
      </div>
    </div>
  );
}

CompensationView.propTypes = {
  contact: PropTypes.object,
  renderLabel: PropTypes.func,
  renderActions: PropTypes.func,
  editingField: PropTypes.string,
  register: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func
};
