import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WarningIcon from '@mui/icons-material/Warning';
import { Link, Popover } from '@mui/material';
import styles from '../../../variables.scss';

import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Zoom
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { get } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { getMessage } from '../../../Containers/Commons/OffLimitsWarningPopup/utils';
import AssignTag from '../../../Containers/Contacts/AssignTag';
import { AddToPipelineBtn } from '../../../Containers/Searches/ViewProject/Comman';
import contact_image from '../../../assets/images/default_contact.png';
import AddContactToProject from '../../../components/common/AddContactToProject';
import { AllDocumentsApi, CandidateStageSetupAPi, contactDocumentApi, getPdlAndContactDataApi, ignContactDataApi, pdlDataRefreshApi } from '../../../services/ApiService';
import { CONTACT_DRAWER_HEADER_ADDITION_CONTENT_KEYS, CONTACT_STATUS, DEFAULT_CONTACT_STATUS, ERROR, GET, IGN_API, PATCH, POST, SUCCESS } from '../../../services/constantService';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { useCustomMessageHook } from '../../../utils/Hooks/useCustomMessageHook';
import { useOfflimit } from '../../../utils/Hooks/useOfflimit';
import { convertArrayBufferToBlobUrl } from '../../../utils/common';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import { addHttps } from '../../../utils/string';
import variables from '../../../variables.scss';
import { ButtonDropDown } from '../../common/ButtonDropdown';
import CustomConfirmationPopup from '../../common/CustomConfirmationPopup';
import CustomPopover from '../../common/CustomPopover';
import MarkOffLimits from '../../common/MarkOffLimits';
import OffLimitWarning from '../../common/OffLimitWarning';
import ShowOffLimits from '../../common/ShowOffLimits';
import CustomInputField from '../../common/StyledComponents/CustomInputField';
import UpdateContactInfo from '../../common/UpdateContactInfo';
import EnrichContact from '../EnrichContact/EnrichContact';
import CandidateActions from './CandidateActions';
import './index.scss';

const AvatarStyle = withStyles({
  root: {
    height: '150px',
    width: '140px'
  }
})(Avatar);

const ViewContactHeader = (props, ref) => {
  const {
    data,
    avatarSizeXs,
    avatarSizeMd,
    avatarSizeSm,
    mainContentWidthXs,
    mainContentWidthMd,
    mainContentWidthSm,
    buttonSizeXs,
    buttonSizeMd,
    buttonSizeSm,
    updateField,
    register,
    unregister,
    setValue = () => {},
    editingField,
    setEditingField = () => {},
    headerDrawer = () => {},
    getHeaderDetails = () => {},
    setIsEdited = () => {},
    setIsIconOpen = () => {},
    setIsHeaderNav = () => {},
    isHeaderNav,
    isIconOpen,
    setIsHeaderOpen = () => {},
    isHeaderOpen,
    offLimitItems,
    setLoading = false,
    allContact,
    fetchProjectAndActivitiesData,
    projectAndActivitiesData,
    handleProjectClick,
    isCandidateProductOne = false,
    project_id,
    selectedCandidate,
    changeStage,
    handleCandidateColorChange,
    fromCandidatePipeline,
    refetchProductOneCandidates
  } = props;

  /* const [editing, setEditing] = React.useState({}); */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [viewReasonAnchorEl, setViewReasonAnchorEl] = React.useState(null);
  const [anchorElType, setAnchorElType] = React.useState(null);
  const [selectedItem, setSelectedItem] = useState(DEFAULT_CONTACT_STATUS);
  const [dialogopen, setDialogopen] = React.useState(false);
  const [stages, setStages] = React.useState([]);
  // const [anchorElHoverPopup, setAnchorElHoverPopup] = React.useState(null);
  const [anchorElAddByHoverPopup, setAnchorElAddByHoverPopup] = useState(null);
  const [isAddToProjectOpen, setIsAddToProjectOpen] = useState(false);
  const { contactOffLimit, createDirectOfflimit, getOffLimitFromSummary, updateDirectOfflimit, deleteDirectOfflimit } = useOfflimit();
  const [isDirectOffLimitPopupOpen, setIsDirectOffLimitPopupOpen] = useState(false);
  const [showContactOffLimit, setShowContactOfflimit] = useState(false);
  const isOffLimitApplied = !!contactOffLimit.length > 0;

  const offLimitClass = useMemo(() => {
    const isHardLimitPresent = contactOffLimit?.find(offlimit => offlimit?.severity === 'hard');
    return isHardLimitPresent ? 'hard' : 'soft';
  }, [contactOffLimit]);

  const isDirectLimitApplied = contactOffLimit.find(entry => entry.type === 'Direct');
  const [profileUrl, setProfileUrl] = useState('');
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
  // const [isShowProjects, setIsShowProjects] = useState(true);
  const [isShowAddedBy, setIsShowAddedBy] = useState(true);
  const edit = {
    first_name: false
  };
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [docs, setDocs] = useState([]);
  const { translateMessage } = useCustomMessageHook();

  const [editing, setEditing] = useState(edit);
  const maxVisibleTags = 1;
  const commonLimitForOpenHeader = 30;
  const commonLimitForcloseHeader = 30;
  const [offLimitWarningPopup, setOffLimitWarningPopup] = useState(false);
  const [isEnrichDataDialogOpen, setIsEnrichDataDialogOpen] = useState(false);
  const [comparisonData, setComparisonData] = useState(null);
  const openOfflimitWarningPopup = () => setOffLimitWarningPopup(true);
  const handleOfflimitWarningPopupClose = () => setOffLimitWarningPopup(false);
  const [_, updateContactData] = useState({});

  const getLimitedString = (string, limit) => {
    if (string?.length <= limit) {
      return string;
    } else {
      const limitString = string?.substring(0, limit) + '...';
      return <Tooltip title={string}>{limitString}</Tooltip>;
    }
  };

  const handleAssignTag = () => {
    setIsTagPopupOpen(true);
  };

  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_UPLOAD_RESUME,
    TRANS_KEYS.CONTACTS_FIRST_NAME,
    TRANS_KEYS.OFF_LIMIT_VIEW_REASONS,
    TRANS_KEYS.OFF_LIMITS_CAUSE,
    TRANS_KEYS.OFF_LIMITS_REASONS,
    TRANS_KEYS.OFF_LIMIT_DETAILS,
    TRANS_KEYS.CONTACTS_LAST_NAME,
    TRANS_KEYS.CONTACTS_DELETE_CONTACT,
    TRANS_KEYS.CONTACTS_VIEW_LINKEDIN
  ]);

  useEffect(() => {
    if (!data?.id) return;
    getOffLimitFromSummary(data?.id);
  }, [data?.id]);

  const updateContact = async id => {
    const response = await ignContactDataApi(GET, id);
    setSelectedItem(response?.data?.contact_status_tv?.short_desc);
    updateContactData(response?.data);
  };

  useEffect(() => {
    updateContact(data?.id).catch(err => console.log(err));
    // setSelectedItem(data.contact_status_tv.short_desc)
  }, [data?.id]);

  useEffect(() => {
    register('profile_img');
    register('additionalDetails');
    register('first_name');
    register('last_name');
    register('contact_name');
    return () => {
      unregister('profile_img');
      unregister('additionalDetails');
      unregister('first_name');
      unregister('last_name');
      unregister('contact_name');
    };
  }, [register, unregister]);

  useEffect(() => {
    const accessToken = async () => {
      const response = await contactDocumentApi(GET, data?.id, null, null, null, 'image');
      if (response.status === 200) {
        const url = convertArrayBufferToBlobUrl(response?.data?.Body?.data);
        setProfileUrl(url);
      } else {
        setProfileUrl(contact_image);
      }
    };
    accessToken();
  }, [data]);

  useEffect(() => {
    setValue('contact_name', {
      first_name: data.first_name,
      last_name: data.last_name
    });
  }, []);

  const handleEnrichDataClick = async () => {
    setIsEnrichDataDialogOpen(true);
    let response = null;
    if (data.id) {
      let pdlRefreshPayload = [];
      pdlRefreshPayload.push({
        id: data.id,
        linkedin_url: data.linkedin_url
      });
      const pdlRefreshResponse = await pdlDataRefreshApi(pdlRefreshPayload);
      response = await getPdlAndContactDataApi(data.id);
      setComparisonData(response.data); // Set the response in the state comparisonData
    }
  };

  const handleEnrichDataDialogClose = () => {
    setIsEnrichDataDialogOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (data.id) {
          const response = await contactDocumentApi(GET, data.id, null, null, null, 'document');
          setDocs(response?.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [data.id, data]);

  // Define a function to convert base64 data to Blob objects and generate URIs
  const convertBase64ToBlob = file => {
    if (file && file.data) {
      try {
        // Convert base64 to binary
        const binaryData = atob(file.data);
        const uint8Array = new Uint8Array([...binaryData].map(char => char.charCodeAt(0)));

        // Create Blob from Uint8Array
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        const uri = window.URL.createObjectURL(blob);
        console.log('Blob URL:', uri);
        return {
          uri: uri,
          fileName: file.fileName
        };
      } catch (error) {
        console.error('Error creating blob:', error);
        return null;
      }
    } else {
      console.error('File or file data is null or undefined:', file);
      return null;
    }
  };
  const open = Boolean(anchorEl);
  const openType = Boolean(anchorElType);

  const saveResume = data => {
    setSelectedFile(data);
    if (data) {
      setOpenConfirmationPopup(true);
    }
  };
  const sub_route1 = `${IGN_API.add_document}/${data?.id}/attachments`;

  const onConfirm = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append('file_name', selectedFile.name);
    bodyFormData.append('file_type', 'resume');
    bodyFormData.append('file', selectedFile);
    setLoading(true);
    const { status, data } = await AllDocumentsApi(POST, sub_route1, bodyFormData);
    processUpload(status, data, selectedFile.name);
  };

  const processUpload = (status, data, fileName) => {
    if (status === 200) {
      setLoading(false);
      // getContactDetails(data?.id)
      getHeaderDetails();
      const message = translateMessage('Successfully', false, `${fileName}`, 'uploaded');
      enqueueSnackbar(message, { variant: SUCCESS });
      setOpenConfirmationPopup(false);
    } else {
      const message = translateMessage('UnableMessage', false, `${fileName}`, 'upload');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };

  const handleClickOpen = () => {
    setDialogopen(true);
  };
  const handleClose1 = () => {
    setDialogopen(false);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleOffLimitClick = event => {
    setAnchorElType(event.currentTarget);
  };
  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOffLimitClose = () => {
    setAnchorElType(null);
  };

  // TODO may require in future
  // const maxVisibleProjects = 1;

  const maxVisibleAddedBy = 1;

  const toggleHeader = () => {
    if (isHeaderOpen) {
      setIsHeaderOpen(!isHeaderOpen);
      setIsIconOpen('icon-open');
      setIsHeaderNav('closed');
      headerDrawer('closed');
    } else {
      setIsHeaderOpen(!isHeaderOpen);
      setIsIconOpen('icon-closed');
      setIsHeaderNav('open');
      headerDrawer('open');
    }
  };
  const handleEditClick = type => {
    setEditing(prevState => ({ ...prevState, [type]: true }));
  };
  const handleCloseClick = type => {
    setEditing(prevState => ({ ...prevState, [type]: false }));
  };
  useImperativeHandle(ref, () => ({
    handleCloseClick: type => {
      return handleCloseClick(type);
    }
  }));
  // TODO may require is future
  // const openProjectPopup = (event) => {
  //   if (isShowProjects) {
  //     setIsShowProjects(!isShowProjects);
  //     setAnchorElHoverPopup(event.currentTarget);
  //   } else {
  //     setIsShowProjects(!isShowProjects);
  //     setAnchorElHoverPopup(null);
  //   }
  // };

  const openAddedByPopup = event => {
    setAnchorElAddByHoverPopup(event.currentTarget);
    if (isShowAddedBy) {
      setIsShowAddedBy(!isShowAddedBy);
      setAnchorElAddByHoverPopup(event.currentTarget);
    } else {
      setIsShowAddedBy(!isShowAddedBy);
      setAnchorElAddByHoverPopup(null);
    }
  };

  const handleAddToProjectClose = (refetch = false) => {
    setIsAddToProjectOpen(false);
  };
  const handleCloseDirectOffLimitPopup = () => {
    setIsDirectOffLimitPopupOpen(false);
  };

  const handleOffLimitInfo = () => {
    setShowContactOfflimit(false);
  };

  // TODO may require is future
  // const closePopup = () => {
  //   setAnchorElHoverPopup(null);
  //   setIsShowProjects(true);
  // };

  const closeAddedByPopup = () => {
    setAnchorElAddByHoverPopup(null);
  };

  const onComfirmOfflimitWarning = () => {
    setIsAddToProjectOpen(true);
    handleOfflimitWarningPopupClose();
    handleClose();
  };

  const handleMenuItemClick = async item => {
    const fieldValue = get(item, 'field_value');

    if (item === 'Project') {
      if (isOffLimitApplied) {
        openOfflimitWarningPopup();
      } else {
        setIsAddToProjectOpen(true);
        handleClose();
      }
    }
    if (fieldValue === 'off_limits') {
      if (isOffLimitApplied) {
        setShowContactOfflimit(true);
      } else {
        setIsDirectOffLimitPopupOpen(true);
      }
      handleOffLimitClose();
    } else if (!(item === 'Project') && !(item === 'List')) {
      setSelectedItem(item?.short_desc);
      handleOffLimitClose(); // Close the Menu after clicking an item (assuming you have this function)
      setLoading(true);
      await ignContactDataApi(PATCH, data?.id, { contact_status: item?.field_value });
      setLoading(false);
    }
  };

  const handleProfileImage = e => {
    const file = e.target.files[0];

    if (file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
        enqueueSnackbar('Please upload a valid image file (jpeg, png, jpg)', { variant: 'error' });
        return;
      }
      setValue('profile_img', e.target.files);
      const fr = new FileReader();
      fr.onload = function () {
        setProfileUrl(fr.result);
      };
      fr.readAsDataURL(file);
    }
  };
  const handleCloseClickProfileImg = () => {
    setEditingField('');
  };

  const handleMouseEnter = event => {
    setViewReasonAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setViewReasonAnchorEl(null);
  };

  const closeConfirmationPopup = () => {
    setOpenConfirmationPopup(false);
  };

  const showOffLimitPopup = () => {
    const offLimitPopOverOpen = Boolean(viewReasonAnchorEl);
    const id = offLimitPopOverOpen ? 'simple-popover' : undefined;
    const open = Boolean(viewReasonAnchorEl);
    const contactName = (data?.offlimit && data.offlimit[0]?.name) || '';
    const reasons = (data?.offlimit && data.offlimit[0]?.reasons) || [];
    if (reasons?.length > 0) {
      return (
        <React.Fragment>
          <Link className='view-reason-txt fs-10' onMouseEnter={handleMouseEnter}>
            {t(`${TRANS_KEYS.OFF_LIMIT_VIEW_REASONS}:key`)}{' '}
          </Link>
          <Popover
            id={id}
            open={open}
            onClose={handleMouseLeave}
            anchorEl={viewReasonAnchorEl}
            className='reason-container'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label='spanning table'>
                <TableHead>
                  <TableRow className='theme-bg-color'>
                    <TableCell className='theme-text-color' align='center' colSpan={2}>
                      {t(`${TRANS_KEYS.OFF_LIMITS_REASONS}:key`)}
                    </TableCell>
                  </TableRow>
                  <TableRow className='theme-bg-cool-grey'>
                    <TableCell align='center'>{t(`${TRANS_KEYS.OFF_LIMITS_CAUSE}:key`)}</TableCell>
                    <TableCell align='center'>{t(`${TRANS_KEYS.OFF_LIMIT_DETAILS}:key`)}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reasons.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align='center' className='theme-bg-error-color'>
                          {item.cause}
                        </TableCell>
                        <TableCell align='center'>{getMessage(item, contactName)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Popover>
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  };

  // const openPopupViewMore = Boolean(anchorElHoverPopup);
  const openAddedbyViewMore = Boolean(anchorElAddByHoverPopup);

  const [additionalContent, setAdditionalContent] = useState(null);
  const onAdditionalContentClick = key => {
    if (key === CONTACT_DRAWER_HEADER_ADDITION_CONTENT_KEYS.JOB_INFO) {
      setAdditionalContent({ key, data: { id: data?.id, current_job_title: data?.current_job_title, contact_company: data?.contact_company } });
    }
  };
  const handleAdditionalContentClose = () => {
    setAdditionalContent(null);
  };

  const renderActions = (field, required = false, onCloseClick) => {
    return (
      <>
        <Box component='span' className='action-icon' onClick={() => updateField(field, required, onCloseClick)}>
          <DoneIcon className='fs-12' cursor='pointer' color='primary' />
        </Box>
        <Box component='span' className='action-icon' onClick={() => onCloseClick(field)}>
          <CloseIcon className='fs-12' cursor='pointer' color='error' />
        </Box>
      </>
    );
  };

  const onSubmitOffLimit = async (offLimitData, setIsLoading) => {
    const obj = { ...offLimitData, contactID: data?.id };
    const isEditMode = showContactOffLimit && !!isDirectLimitApplied;
    const contactOffLimitAction = isEditMode ? updateDirectOfflimit : createDirectOfflimit;
    await contactOffLimitAction(obj);
    setIsLoading(false);
    handleCloseDirectOffLimitPopup();
    handleOffLimitInfo();
  };

  const onDeleteContactOffLimit = async setIsLoading => {
    await deleteDirectOfflimit(data?.id);
    setIsLoading(false);
    handleCloseDirectOffLimitPopup();
    handleOffLimitInfo();
  };

  const handleMoreActions = action => {
    if (action === data?.addButtonLabel) {
      handleMenuItemClick('Project');
    }
    if (action === data?.viewResumeButton) {
      handleClickOpen();
    }
  };

  const fetchCandidateStages = () => {
    CandidateStageSetupAPi(GET, `/all?projectId=${project_id}&showLogicalStages=false`).then(res => {
      setStages(res.data);
    });
  };

  const stagesWithoutLogical = stages?.filter(stage => !stage?.isLogicalStage)?.map(stage => stage?.stage_name);

  useEffect(() => {
    if (project_id) {
      fetchCandidateStages();
    }
  }, [project_id]);

  const MoreActionsBtn = () => {
    return (
      <ButtonDropDown
        iconRight={<ArrowDropDownIcon />}
        options={[
          data?.addButtonLabel,
          data?.resume && docs?.length !== 0 ? (
            data?.viewResumeButton
          ) : (
            <div className='resume-button'>
              <FileUploader handleChange={saveResume}>{t(`${TRANS_KEYS.CONTACTS_UPLOAD_RESUME}:key`)}</FileUploader>
            </div>
          )
        ]}
        ellipsis
        buttonText={t('actions.moreActions')}
        className={'my-2'}
        onClick={handleMoreActions}
        buttonStyle={{
          textTransform: 'none',
          fontWeight: 500,
          border: '0.1px solid grey'
        }}
        MenuItemStyle={{
          cursor: 'pointer',
          borderBottom: '1px solid black',
          fontSize: '14px',
          fontWeight: 500
        }}
      />
    );
  };

  return (
    <>
      {isAddToProjectOpen && <AddContactToProject isPopupOpen={isAddToProjectOpen} handleClose={handleAddToProjectClose} data={allContact} />}
      <Dialog onClose={handleClose1} open={dialogopen} id='resume-dialog'>
        {docs && <DocViewer documents={docs?.map(file => convertBase64ToBlob(file))} pluginRenderers={DocViewerRenderers} />}
      </Dialog>

      <Box id='view-contact-header-container' pb={2}>
        <Paper elevation={3} className={`activity-bars-head ${data.disableEditing === false ? `header${isHeaderNav}` : 'logan-activity-head'}`}>
          {isHeaderNav === 'closed' ? (
            <Stack direction='row' spacing={0} className='main-sub-content-closed d-flex align-items-center px-2'>
              <Box className='main-container d-flex align-items-center' gap={1}>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <Box className={`avatar-preview ${!isOffLimitApplied && selectedItem === DEFAULT_CONTACT_STATUS ? 'border-green' : 'border-red'}`}>
                    <Avatar sx={{ width: '28px', height: '28px' }} src={profileUrl} />
                  </Box>
                  <Typography fontWeight={600} fontSize={15}>{`${data?.first_name} ${data?.last_name}`}</Typography>

                  {data?.linkedin_url ? (
                    <a target='_blank' rel='noopener noreferrer' href={addHttps(data?.linkedin_url)}>
                      <Tooltip
                        title={t(`${TRANS_KEYS.CONTACTS_VIEW_LINKEDIN}:key`)}
                        placement='top'
                        TransitionComponent={Zoom}
                        arrow
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: { offset: [0, -5] }
                              }
                            ]
                          }
                        }}
                      >
                        <LinkedInIcon className='linkedin-icon-header fs-14' />
                      </Tooltip>
                    </a>
                  ) : (
                    <LinkedInIcon className='disabled-link fs-14' />
                  )}

                  <Box display='flex' justifyContent='center'>
                    {offLimitItems && offLimitItems.length > 0 && (
                      <Button
                        className={`off-limit-text-container ${isOffLimitApplied ? `offLimit-border-${offLimitClass}` : selectedItem === DEFAULT_CONTACT_STATUS ? 'button-green' : 'button-red'}`}
                        size='small'
                        aria-controls='offlimit-menu'
                        aria-haspopup='true'
                        onClick={handleOffLimitClick}
                        endIcon={<ExpandMoreIcon className={`type-icon ${openType ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                      >
                        <Box component='span' className='off-limit-text fs-8'>
                          {capitalize(selectedItem || DEFAULT_CONTACT_STATUS)}
                        </Box>
                        {isOffLimitApplied && (
                          <Box
                            onClick={e => {
                              e.stopPropagation();
                            }}
                            className='off-limit-indicator'
                          >
                            <WarningIcon
                              className={`offlimit-caution ${isOffLimitApplied ? offLimitClass : ''}`}
                              sx={{
                                fontSize: 20
                              }}
                            />
                          </Box>
                        )}
                      </Button>
                    )}

                    {offLimitItems && offLimitItems.length > 1 && (
                      <Menu id='offlimit-menu' anchorEl={anchorElType} keepMounted open={openType} onClose={handleOffLimitClose}>
                        {offLimitItems.map((item, index) => (
                          <MenuItem key={index} onClick={() => handleMenuItemClick(item)} className='fs-12'>
                            {item?.short_desc === 'Off-Limits' && isOffLimitApplied ? `View ${capitalize(item?.short_desc)}` : capitalize(item?.short_desc)}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </Box>
                </Box>

                <Box className='d-flex' gap={2}>
                  {data?.additionalContent &&
                    Object.keys(data?.additionalContent)
                      .slice(0, 2)
                      .map(contentKey => (
                        <Box key={contentKey} alignItems={'center'}>
                          {data?.additionalContent[contentKey]?.icon || null}
                          <Typography color={styles.text} fontWeight={500} fontSize={14} component='span'>
                            {getLimitedString(data?.additionalContent[contentKey]?.text, commonLimitForcloseHeader)}
                          </Typography>
                        </Box>
                      ))}

                  <Box display={'flex'} alignItems={'center'} gap={0.5}>
                    <Typography component='span' fontWeight={500} fontSize={14} className='content-title'>
                      {data?.sourceTitle || ''}
                    </Typography>
                    <Typography color={styles.text} fontWeight={500} fontSize={14} component='span'>
                      {data?.source || '-'}
                    </Typography>
                  </Box>
                </Box>

                {!fromCandidatePipeline && !isCandidateProductOne && <MoreActionsBtn />}
                {fromCandidatePipeline && (
                  <CandidateActions
                    selectedCandidate={selectedCandidate}
                    handleCandidateColorChange={handleCandidateColorChange}
                    changeStage={changeStage}
                    stagesWithoutLogical={stagesWithoutLogical}
                    isHeaderClose={isHeaderNav === 'closed'}
                  />
                )}

                {isCandidateProductOne && <AddToPipelineBtn id={selectedCandidate?.id} stage={selectedCandidate?.stage} refetch={refetchProductOneCandidates} />}
              </Box>
            </Stack>
          ) : (
            <Stack direction='row' spacing={0} className='main-sub-content px-2'>
              <Grid container className='main_grid_row' spacing={0}>
                {/* **************profile-pic action button***********start: expand ***/}
                <Grid item xs={avatarSizeXs} md={avatarSizeMd} sm={avatarSizeSm} className='d-flex main_grid_2'>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {data?.avatar && data?.disableEditing ? (
                      <Box className={`avatar-preview ${selectedItem === DEFAULT_CONTACT_STATUS ? 'border-green' : 'border-red'} mr-1`}>
                        <Box id='imagePreview'>
                          <AvatarStyle className='log-an-activity-profile-image' src={profileUrl} />
                        </Box>
                      </Box>
                    ) : (
                      <Box className='avatar-upload'>
                        <Box className='avatar-edit'>
                          <input
                            type='file'
                            id='imageUpload'
                            accept='.png, .jpg, .jpeg'
                            // onClick={(e) => {
                            //   e.target.value = null;
                            //   setNewProfilePic(null)
                            // }}
                            onClick={() => {
                              setEditingField('profile_img');
                            }}
                            onChange={e => handleProfileImage(e)}
                            className='d-none'
                          />
                          <InputLabel htmlFor='imageUpload'>
                            <Box>
                              <EditIcon className='editIcon fs-40' />
                            </Box>
                          </InputLabel>
                        </Box>
                        <Box position={'relative'}>
                          <Box className={`avatar-preview ${!isOffLimitApplied && selectedItem === DEFAULT_CONTACT_STATUS ? 'border-green' : 'border-red'} mr-1`}>
                            <Box id='imagePreview'>
                              <AvatarStyle className='image-size' src={profileUrl} />
                            </Box>
                          </Box>
                          {editingField === 'profile_img' && (
                            <Box position={'absolute'} top={0} right={-12}>
                              {renderActions('profile_img', true, handleCloseClickProfileImg)}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}

                    <Box display='flex' justifyContent='center' pt={1}>
                      {offLimitItems && offLimitItems.length > 0 && (
                        <Button
                          className={`off-limit-text-container-collapse position-relative ${isOffLimitApplied ? `offLimit-border-${offLimitClass}` : selectedItem === DEFAULT_CONTACT_STATUS ? 'button-green' : 'button-red'}`}
                          size='small'
                          aria-controls='offlimit-menu'
                          aria-haspopup='true'
                          onClick={handleOffLimitClick}
                          endIcon={<ExpandMoreIcon className={`type-icon ${openType ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                        >
                          <Box component='span' className='off-limit-text fs-9'>
                            {capitalize(selectedItem || DEFAULT_CONTACT_STATUS)}
                          </Box>
                          {isOffLimitApplied && (
                            <Box
                              onClick={e => {
                                e.stopPropagation();
                              }}
                              className='off-limit-indicator'
                            >
                              <Box>
                                <WarningIcon className={`offlimit-caution ${isOffLimitApplied ? offLimitClass : ''}`} size='10px' />
                              </Box>
                            </Box>
                          )}
                        </Button>
                      )}
                    </Box>
                    {selectedItem === CONTACT_STATUS.OFF_LIMITS && <React.Fragment>{showOffLimitPopup()}</React.Fragment>}
                    {offLimitItems && offLimitItems.length > 1 && (
                      <Menu id='offlimit-menu' anchorEl={anchorElType} keepMounted open={openType} onClose={handleOffLimitClose}>
                        {offLimitItems.map((item, index) => (
                          <MenuItem key={index} onClick={() => handleMenuItemClick(item)} className='fs-12'>
                            {item?.short_desc === 'Off-Limits' && isOffLimitApplied ? `View ${capitalize(item?.short_desc)}` : capitalize(item?.short_desc)}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </div>
                </Grid>
                {/* **************profile-pic action button**********end: expand ****/}
                {/* **************middle-content**********start: expand ****/}
                <Grid item xs={mainContentWidthXs} md={mainContentWidthMd} sm={mainContentWidthSm} pl={2} pt={2} className='main_grid_8'>
                  {/* **************Name-linkedin-delete-icon**********start: expand ****/}
                  <Box className='d-flex align-items-center'>
                    {editing?.first_name && data?.disableEditing === false ? (
                      <Box className='d-flex align-items-center content-space'>
                        <Box className='w-50 mr-2' pb={1}>
                          <CustomInputField
                            defaultValue={data?.first_name || ''}
                            onChange={e => {
                              setValue('contact_name.first_name', e.target.value);
                            }}
                            label={t(`${TRANS_KEYS.CONTACTS_FIRST_NAME}:key`)}
                          />
                        </Box>
                        <Box className='w-50' pb={1}>
                          <CustomInputField
                            defaultValue={data?.last_name || ''}
                            onChange={e => {
                              setValue('contact_name.last_name', e.target.value);
                            }}
                            label={t(`${TRANS_KEYS.CONTACTS_LAST_NAME}:key`)}
                          />
                        </Box>
                        <Box className='d-flex' pr={1} pb={1}>
                          {renderActions('contact_name', false, () => handleCloseClick('first_name'))}
                        </Box>
                      </Box>
                    ) : (
                      <Box component='span' className='header-font header-text fs-18 mr-2' onDoubleClick={() => handleEditClick('first_name')}>
                        {`${data?.first_name} ${data?.last_name}`}
                      </Box>
                    )}
                    <Tooltip
                      title={t(`${TRANS_KEYS.CONTACTS_EDIT}:key`)}
                      placement='top'
                      TransitionComponent={Zoom}
                      arrow
                      slotProps={{
                        popper: {
                          modifiers: [{ name: 'offset', options: { offset: [0, -5] } }]
                        }
                      }}
                    >
                      {data?.editIcon || null}
                    </Tooltip>
                    {data?.linkedin_url ? (
                      <a target='_blank' rel='noopener noreferrer' href={addHttps(data?.linkedin_url)}>
                        <Tooltip
                          title={t(`${TRANS_KEYS.CONTACTS_VIEW_LINKEDIN}:key`)}
                          placement='top'
                          TransitionComponent={Zoom}
                          arrow
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: { offset: [0, -5] }
                                }
                              ]
                            }
                          }}
                        >
                          <LinkedInIcon className='linkedin-icon-header fs-14' />
                        </Tooltip>
                      </a>
                    ) : (
                      <Tooltip
                        title={t(`${TRANS_KEYS.CONTACTS_VIEW_LINKEDIN}:key`)}
                        placement='top'
                        TransitionComponent={Zoom}
                        arrow
                        slotProps={{
                          popper: {
                            modifiers: [{ name: 'offset', options: { offset: [0, -5] } }]
                          }
                        }}
                      >
                        <LinkedInIcon className='disabled-link fs-14' />
                      </Tooltip>
                    )}
                    <Tooltip
                      title={t(`${TRANS_KEYS.CONTACTS_DELETE_CONTACT}:key`)}
                      placement='top'
                      TransitionComponent={Zoom}
                      arrow
                      slotProps={{
                        popper: {
                          modifiers: [{ name: 'offset', options: { offset: [0, -5] } }]
                        }
                      }}
                    >
                      {data.deleteIcon || null}
                    </Tooltip>
                  </Box>
                  {/* ***************Name-linkedin-delete-icon**********end: expand ****/}
                  <Divider />
                  <Box className='d-flex fs-12 mt-6'>
                    <Grid container spacing={0} className='main_grid_box'>
                      {/* ***************FIRST COLUMN DATA (additional content)**********start: expand ****/}
                      <Grid item xs={6} md={3.5} sm={6} className={`${data?.additionalContentClass?.className} col_2`}>
                        {data?.additionalContent &&
                          Object.keys(data?.additionalContent).map(contentKey => (
                            <Box onDoubleClick={() => onAdditionalContentClick(contentKey)} key={contentKey} className='mt-2 contact-details-value content-color'>
                              <>
                                {data?.additionalContent[contentKey]?.icon || null}
                                <Box
                                  component='span'
                                  onClick={data?.additionalContent[contentKey]?.text ? data?.additionalContent[contentKey]?.onClick : () => {}}
                                  sx={{
                                    cursor: `${data?.additionalContent[contentKey]?.onClick ? 'pointer' : 'default'}`
                                  }}
                                >
                                  {getLimitedString(data?.additionalContent[contentKey]?.text, commonLimitForOpenHeader)}
                                </Box>
                              </>
                            </Box>
                          ))}
                      </Grid>
                      {/* ***************FIRST COLUMN DATA**********end: expand ****/}
                      {data?.mainContent ? null : (
                        <>
                          {/* ***************SECOND COLUMN DATA (recent- engagement -tag)**********start: expand ****/}
                          <Grid item xs={6} md={3.5} sm={6} className={'col_2'}>
                            <Box
                              className='mt-2'
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                alignItems: 'center'
                              }}
                            >
                              <Box component='span' className='content-title fs-12 mr-2'>
                                {data?.lastActivityTime.icon || ''}
                              </Box>
                              <Box component='span'>{data.lastActivityTime.text || '-'}</Box>
                            </Box>
                            <Box
                              className='mt-2'
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                alignItems: 'center'
                              }}
                            >
                              <Box component='span' className='content-title fs-12'>
                                {data?.recentEngagement?.title || ''}
                              </Box>
                              <Box component='span'>{data?.recentEngagement?.text || ''}</Box>
                            </Box>
                            <AssignTag
                              tagPopUp={data?.contact_tags}
                              tagId={data?.id}
                              setIsPopupOpen={setIsTagPopupOpen}
                              isPopupOpen={isTagPopupOpen}
                              label={t(`${TRANS_KEYS.CONTACTS_SELECT_TAG}:key`)}
                              getHeaderDetails={getHeaderDetails}
                              setIsEdited={setIsEdited}
                            />
                            <Box className='mt-2 d-flex'>
                              <Box component='span' className='content-title fs-12 mr-2'>
                                {data?.tagsTitle || ''}
                              </Box>
                              <Box>
                                <Box className='tag-container'>
                                  {data?.contact_tags.length !== 0 &&
                                    data?.contact_tags.slice(0, maxVisibleTags).map((tag, index) => (
                                      <Box key={index} className={`tag-border mr-2 tag${tag?.ign_tags?.name}`} style={{ color: tag?.ign_tags?.color }}>
                                        {tag?.ign_tags?.name}
                                      </Box>
                                    ))}
                                  <Box className='d-flex align-items-center justify-content-center font-weight-bold fs-10'>
                                    <Link
                                      component='button'
                                      onClick={() => {
                                        handleAssignTag();
                                      }}
                                      className='add-tags-btn fs-10'
                                    >
                                      +Tags
                                    </Link>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                          {/* ***************SECOND COLUMN DATA (recent- engagement -tag)**********end: expand ****/}
                          {/* ***************THIRD COLUMN DATA (added_by and project)**********start: expand ****/}
                          <Grid item xs={12} md={5} sm={12} className='col_8'>
                            {data?.addedByTitle && (
                              <Box className='mt-2 d-flex'>
                                <Box component='span' className='content-title fs-12 mr-2'>
                                  {data?.addedByTitle || ''}
                                </Box>

                                {data?.contact_added_by?.length !== 0 ? (
                                  <Box className='d-flex'>
                                    {data?.contact_added_by.slice(0, maxVisibleAddedBy).map((addedBy, index) => (
                                      <Box
                                        key={index}
                                        component='span'
                                        // removed classes: rectangle-blue p-1
                                        className='fs-12 mr-2'
                                      >
                                        {/* <Box component="span" pr={1}>
                                          {data.addedBy?.icon
                                            ? data.addedBy?.icon
                                            : ""}
                                        </Box> */}
                                        {addedBy?.added_user?.name || ''}
                                      </Box>
                                    ))}
                                    {data.contact_added_by.length > maxVisibleAddedBy && (
                                      <Box className='fs-12 d-flex align-items-center'>
                                        <Box
                                          aria-owns={openAddedbyViewMore ? 'mouse-over-popover-added-by' : undefined}
                                          aria-haspopup='true'
                                          onClick={openAddedByPopup}
                                          sx={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                          }}
                                        >
                                          {`+${data?.contact_added_by.length - maxVisibleAddedBy}`}
                                          <CustomPopover
                                            id='mouse-over-popover-added-by'
                                            open={openAddedbyViewMore}
                                            anchorEl={anchorElAddByHoverPopup}
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'left'
                                            }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'left'
                                            }}
                                            onClose={closeAddedByPopup}
                                          >
                                            <Box onClick={event => event.stopPropagation()} sx={{ pointerEvents: 'auto' }}>
                                              {data?.contact_added_by && (
                                                <Box
                                                  className='d-flex flex-column align-items-center overflow-auto'
                                                  sx={{
                                                    maxHeight: '75px',
                                                    height: 'fit-content'
                                                  }}
                                                >
                                                  {data?.contact_added_by.slice(maxVisibleAddedBy).map((addedBy, index) => (
                                                    <Box
                                                      key={index}
                                                      component='span'
                                                      // Removed classes: rectangle-blue p-1
                                                      className='fs-12 m-1'
                                                      sx={{
                                                        borderRadius: '4px',
                                                        backgroundColor: variables.heading,
                                                        boxSizing: 'border-box',
                                                        color: variables.titleBarBackground
                                                      }}
                                                    >
                                                      {/* <Box
                                                        component="span"
                                                        pr={1}
                                                      >
                                                        {data?.addedBy?.icon
                                                          ? data?.addedBy?.icon
                                                          : ""}
                                                      </Box> */}
                                                      {addedBy?.added_user?.name || ''}
                                                    </Box>
                                                  ))}
                                                </Box>
                                              )}
                                            </Box>
                                          </CustomPopover>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                ) : (
                                  '-'
                                )}
                              </Box>
                            )}
                            <Box className='mt-2 d-flex'>
                              <Box component='span' className='content-title fs-12 mr-2'>
                                {data.projectsTitle || ''}
                              </Box>
                              <Box component='span' className='project-count mr-2'>
                                <Link component='button' variant='body2' onClick={handleProjectClick} className='project-count mr-2' sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                                  {projectAndActivitiesData?.length}
                                </Link>
                              </Box>
                              {/* TODO below given code is for the earlier phase where we do show project title with more details. */}
                              {/* {data?.projects?.length > 0 ? (
                                <Box className="d-flex">
                                  <Box className="content-project mr-1">
                                    {data?.projects
                                      ?.slice(0, maxVisibleProjects)
                                      .map((project, index) => (
                                        <Box
                                          key={index}
                                          className="project-content m-1"
                                        >
                                          <Box
                                            component="span"
                                            className="project-name mr-2"
                                          >
                                            {project?.name}
                                          </Box>
                                          <Box
                                            component="span"
                                            className="screened"
                                            sx={{ padding: "2px" }}
                                          >
                                            {project?.screened}
                                          </Box>
                                        </Box>
                                      ))}
                                  </Box>

                                  {data?.projects?.length > maxVisibleProjects && (
                                    <Box className="d-flex align-items-center pl-1 fs-12">
                                      <Box>
                                        <Box
                                          aria-owns={
                                            openPopupViewMore
                                              ? "mouse-over-popover"
                                              : undefined
                                          }
                                          aria-haspopup="true"
                                          onClick={openProjectPopup}
                                          sx={{
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {`+${data?.projects.length - maxVisibleProjects}`}
                                          <CustomPopover
                                            id="mouse-over-popover"
                                            open={openPopupViewMore}
                                            anchorEl={anchorElHoverPopup}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "left",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "left",
                                            }}
                                            onClose={closePopup}
                                          >
                                            <Box
                                              component="span"
                                              className="d-flex justify-content-end align-items-center"
                                              sx={{ height: "3vh" }}
                                              onClick={(event) =>
                                                event.stopPropagation()
                                              }
                                            >
                                              <CloseIcon
                                                className="fs-10 mr-2 cursor-pointer"
                                                onClick={closePopup}
                                                sx={{
                                                  height: "2vh",
                                                  width: "2vw",
                                                }}
                                              />
                                            </Box>
                                            {data?.projects && (
                                              <Box
                                                id="view-contact-header-container"
                                                onClick={(event) =>
                                                  event.stopPropagation()
                                                }
                                                sx={{ pointerEvents: "auto" }}
                                              >
                                                <Box
                                                  className="content-project mx-2 mb-2 overflow-auto"
                                                  sx={{
                                                    maxHeight: "80px",
                                                    height: "fit-content",
                                                  }}
                                                >
                                                  {data?.projects
                                                    ?.slice(maxVisibleProjects)
                                                    .map((project, index) => (
                                                      <Box
                                                        key={index}
                                                        className="project-content m-1 d-flex justify-content-between"
                                                      >
                                                        <Box
                                                          component="span"
                                                          className="project-name fs-12 mr-2"
                                                        >
                                                          {project?.name}
                                                        </Box>
                                                        <Box
                                                          component="span"
                                                          className="screened p-1 fs-12"
                                                        >
                                                          {project?.screened}
                                                        </Box>
                                                      </Box>
                                                    ))}
                                                </Box>
                                              </Box>
                                            )}
                                          </CustomPopover>
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              ) : ("-")} */}
                            </Box>
                            <Box
                              className='mt-2'
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                alignItems: 'center'
                              }}
                            >
                              <Box component='span' color={'gray'}>
                                {data.sourceTitle || ''}
                              </Box>
                              <Box component='span'>{data.source || '-'}</Box>
                            </Box>
                            {/* <Box
                              className='mt-2'
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                alignItems: 'center'
                              }}
                            >
                              <Box component='span' color={'gray'}>
                                {'Enriched on:- '}
                              </Box>
                              <Box component='span'>
                                {data.pdlStatus?.updated_at
                                  ? new Date(data.pdlStatus.updated_at).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/\s/g, '  ')
                                  : '-'}
                              </Box>
                            </Box> */}
                          </Grid>
                          {/* ***************THIRD COLUMN DATA (added_by and project)**********end: expand ****/}
                        </>
                      )}
                    </Grid>
                  </Box>
                </Grid>
                {/* **************middle-content**********end: expand ****/}

                <Grid item xs={buttonSizeXs} md={buttonSizeMd} sm={buttonSizeSm} className={'main_grid_3 d-flex justify-content-center align-items-end'}>
                  {data?.screened ? (
                    <Button
                      className={data?.screen?.className}
                      startIcon={data?.screen?.icon}
                      //endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      endIcon={<ExpandMoreIcon className={`expand-icon ${open ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                      onClick={handleClick}
                    >
                      {data?.screen?.text}
                    </Button>
                  ) : (
                    <Box display={'flex'} alignItems={'end'} flexDirection={'column'}>
                      {isCandidateProductOne && <AddToPipelineBtn id={selectedCandidate?.id} stage={selectedCandidate?.stage} refetch={refetchProductOneCandidates} />}
                      {!isCandidateProductOne && <MoreActionsBtn />}

                      {fromCandidatePipeline && (
                        <CandidateActions
                          selectedCandidate={selectedCandidate}
                          handleCandidateColorChange={handleCandidateColorChange}
                          changeStage={changeStage}
                          stagesWithoutLogical={stagesWithoutLogical}
                          isHeaderClose={isHeaderNav === 'closed'}
                        />
                      )}
                      <Button
                        variant='outlined'
                        className='my-2'
                        size='medium'
                        onClick={handleEnrichDataClick}
                        startIcon={<AcUnitIcon className='fs-13' sx={{ color: data.pdlStatus && data.pdlStatus ? 'green' : 'red' }} />}
                        sx={{
                          textTransform: 'none',
                          fontWeight: 300,
                          border: '0.1px solid grey',
                          width: '100%',
                          height: '32px',
                          fontSize: '12px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        Enrich Data
                      </Button>
                      <EnrichContact open={isEnrichDataDialogOpen} onClose={handleEnrichDataDialogClose} data={data} />
                    </Box>
                  )}
                  {/* 20th March 2024: Temporary remove menu and only used add to project */}
                  {/* {data.actionItems ? (
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleClose}
                    >
                      {data.actionItems &&
                        data.actionItems.map((item, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => handleMenuItemClick(item)}
                          >
                            {item}
                          </MenuItem>
                        ))}
                    </Menu>
                  ) : null} */}
                  {additionalContent?.key === CONTACT_DRAWER_HEADER_ADDITION_CONTENT_KEYS.JOB_INFO && (
                    <UpdateContactInfo
                      isPopupOpen={false} // feature removed
                      handleClose={handleAdditionalContentClose}
                      data={additionalContent?.data || ''}
                      getHeaderDetails={getHeaderDetails}
                    />
                  )}
                </Grid>
              </Grid>
            </Stack>
          )}
        </Paper>
      </Box>

      {isDirectOffLimitPopupOpen && (
        <MarkOffLimits
          data={[isDirectLimitApplied]}
          editMode={showContactOffLimit && !!isDirectLimitApplied}
          isPopupOpen={isDirectOffLimitPopupOpen}
          handleClose={handleCloseDirectOffLimitPopup}
          onSubmit={onSubmitOffLimit}
          onDelete={onDeleteContactOffLimit}
        />
      )}

      {showContactOffLimit && (
        <ShowOffLimits
          showInherited={true}
          showDirect={true}
          isPopupOpen={true}
          handleClose={handleOffLimitInfo}
          onEdit={() => {
            setIsDirectOffLimitPopupOpen(true);
          }}
          data={contactOffLimit}
        />
      )}

      <OffLimitWarning open={offLimitWarningPopup} onClose={handleOfflimitWarningPopupClose} onConfirm={onComfirmOfflimitWarning} offlimits={contactOffLimit} />

      <CustomConfirmationPopup
        open={openConfirmationPopup}
        onClose={closeConfirmationPopup}
        type='Upload Document'
        deletingItem='contact'
        customMessage='Are you sure you want Upload Resume?'
        // deletingItemName={contact?.data?.name}
        cancelText='No'
        confirmText='Yes'
        onConfirm={onConfirm}
        setOpen={setOpenConfirmationPopup}
      />

      {data.disableEditing === false && (
        <Box id='view-header-icon' className='toggle-header' onClick={toggleHeader}>
          <ExpandMoreRoundedIcon cursor='pointer' className={`header-arrow-icon fs-20 ${isIconOpen}`} />
        </Box>
      )}
    </>
  );
};

ViewContactHeader.propTypes = {
  data: PropTypes.object,
  avatarSizeXs: PropTypes.number,
  avatarSizeMd: PropTypes.number,
  avatarSizeSm: PropTypes.number,
  mainContentWidthXs: PropTypes.number,
  mainContentWidthMd: PropTypes.number,
  mainContentWidthSm: PropTypes.number,
  buttonSizeXs: PropTypes.number,
  buttonSizeMd: PropTypes.number,
  buttonSizeSm: PropTypes.number,
  updateField: PropTypes.func,
  register: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  headerDrawer: PropTypes.func,
  watch: PropTypes.func,
  editingField: PropTypes.string,
  setEditingField: PropTypes.func,
  getHeaderDetails: PropTypes.func,
  setIsEdited: PropTypes.func,
  setIsHeaderBar: PropTypes.func,
  isHeaderBar: PropTypes.string,
  setIsIconOpen: PropTypes.func,
  isIconOpen: PropTypes.string,
  setIsHeaderOpen: PropTypes.func,
  isHeaderOpen: PropTypes.bool,
  setIsHeaderNav: PropTypes.func,
  isHeaderNav: PropTypes.string,
  offLimitItems: PropTypes.array,
  setLoading: PropTypes.bool,
  allContact: PropTypes.object,
  fetchProjectAndActivitiesData: PropTypes.func,
  projectAndActivitiesData: PropTypes.string,
  handleProjectClick: PropTypes.func,
  isCandidateProductOne: PropTypes.bool,
  project_id: PropTypes.string,
  selectedCandidate: PropTypes.object,
  changeStage: PropTypes.func,
  handleCandidateColorChange: PropTypes.func,
  fromCandidatePipeline: PropTypes.bool,
  refetchProductOneCandidates: PropTypes.func
};

export default forwardRef(ViewContactHeader);
