//-----------------------------------------------------------// In-built Imports // ------------------------------
import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { CircularProgress } from '@mui/material';

//-----------------------------------------------------------// Internal Imports // ------------------------------
import { DateAndTimeRenderer, getFilterQuery } from '../../Commons/Utils';

export const PAGE_LIMIT = 200;
export const SUCCESS_STATUS_CODE = 200;

const getRequestedBy = params => {
  if (params.value) {
    const name = `${params.value.first_name} ${params.value.last_name}`;
    return name;
  }
  return '';
};
export const columnDefs = () => {
  const columns = [
    {
      resizable: true,
      sortable: true,
      field: 'job_number',
      colId: 'job_number',
      headerName: 'Project#',
      minWidth: 250,
      maxWidth: 250,
      suppressSizeToFit: true,
      cellRenderer: 'IdRenderer',
      tooltipField: 'job_number'
    },
    {
      field: 'job_title',
      colId: 'job_title',
      headerName: 'Project Title',
      minWidth: 250,
      maxWidth: 200,
      tooltipField: 'job_title',
      cellRenderer: 'TitleRenderer'
    },
    {
      field: 'approval_requested_date',
      colId: 'approval_requested_date',
      headerName: 'Request Date',
      cellRenderer: 'DateTimeRenderer',
      tooltipValueGetter: DateAndTimeRenderer,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: 'approve_request_by',
      colId: 'approve_request_by',
      headerName: 'Requested By',
      tooltipValueGetter: getRequestedBy,
      cellRenderer: getRequestedBy,
      minWidth: 250,
      maxWidth: 250
    },
    {
      colId: 'billing_notes',
      field: 'billing_notes',
      headerName: 'Billing Notes',
      minWidth: 250,
      maxWidth: 550,
      width: 550,
      cellRenderer: 'BillingRender',
      cellRendererParams: {
        placement: 'left'
      }
    }
  ];
  return columns;
};

export const CustomLoadingOverlayComponent = () => {
  return <CircularProgress />;
};
export const getFilterParamStringForApprovalSearch = data => {
  return getFilterQuery(data, {}, {}, '');
};
