//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { createFilterOptions } from '@mui/material';
import { picklistDropDownApis } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import CustomDropdown from '../../CustomDropdown';

const CompanySelection = props => {
  const { label = '', placeholder = '', onChange = () => {}, defaultValue, required = false, isForAddCompany = false, value = [], ...rest } = props;

  const { t } = useLanguageHooks([TRANS_KEYS.ADD_COMPANY]);
  const [options, setOptions] = useState([]);
  const [myValue, setValue] = useState([]);
  const filter = createFilterOptions();

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'object' && defaultValue.id && defaultValue.name) {
        if (JSON.stringify(value) !== JSON.stringify(defaultValue)) {
          setValue(defaultValue);
        }
        return;
      }
      const updatedValue = Array.isArray(defaultValue) ? options.filter(op => defaultValue.includes(op?.name)) : defaultValue;
      if (JSON.stringify(value) !== JSON.stringify(updatedValue)) {
        setValue(updatedValue);
      }
    }
  }, [defaultValue, options]);

  useEffect(() => {
    if (value) {
      if (typeof value === 'object' && value.id && value.name) {
        if (JSON.stringify(value) !== JSON.stringify(defaultValue)) {
          setValue(value);
        }
        return;
      }
      const updatedValue = Array.isArray(value) ? options.filter(op => value.map(v => v?.name || v).includes(op?.name)) : value;
      if (JSON.stringify(value) !== JSON.stringify(updatedValue)) {
        setValue(updatedValue);
      }
    }
  }, [value, options]);

  useEffect(() => {
    const getCompanyList = async () => {
      const sub_route = 'companies-picklist';
      const { status, data } = await picklistDropDownApis(GET, sub_route);
      if (status === 200) {
        setOptions(data?.filter(i => i.name));
      } else {
        setOptions([]);
      }
    };
    getCompanyList();
  }, []);
  return (
    <CustomDropdown
      {...rest}
      options={options}
      value={myValue}
      // sx={{
      //   '& .MuiInputLabel-root': {
      //     margin: 0,
      //     top: '-10px'
      //   },
      //   '& .MuiInputLabel-shrink': {
      //     margin: 0,
      //     top: '-10px'
      //   },
      //   '& .Mui-focused .MuiInputLabel-root': {
      //     margin: 0,
      //     top: '-10px'
      //   },
      //   '& .Mui-focused .MuiInputLabel-shrink': {
      //     margin: 0,
      //     top: '-10px'
      //   }
      // }}
      label={label}
      placeholder={placeholder}
      onChange={(event, newValue) => {
        if (newValue && (newValue.inputValue || (Array.isArray(newValue) && newValue?.length && newValue[newValue?.length - 1].inputValue))) {
          // window.open(`${window.location.host}/companies/all-companies/add`, '_blank')
        }
        onChange(event, newValue);
      }}
      defaultValue={defaultValue}
      required={required}
      addAnotherButton={!isForAddCompany}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue) {
          filtered.push({
            inputValue: params.inputValue
          });
        }
        return filtered;
      }}
      addAnotherButtonText={t(`${TRANS_KEYS.ADD_COMPANY}:key`)}
      isToAddCompany={!isForAddCompany}
    />
  );
};

CompanySelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  isForAddCompany: PropTypes.bool,
  value: PropTypes.array
};

export default CompanySelection;
