import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Box, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------

const TotalViewRevenue = props => {
  /* getIndirectFees is used when calculate for estimated and actual fee calculation */
  const { type = 'estimated', getTotalIndirectFee, getRevenue, getTotalPercentageOfBase, billingStatus } = props;
  const label = type === 'estimated' ? 'Estimated' : 'Actual';
  const displayTooltipContent = title => {
    return (
      <Tooltip title={title}>
        <span className='pl-4'>&nbsp;&nbsp;{title}</span>
      </Tooltip>
    );
  };
  return (
    <Box id='team-info'>
      <Box className={'d-flex flex-column ign-team-information-table revenue-table mt-2 fs-14'}>
        <Box className={'d-flex table-heading font-weight-bold fs-15'}>{label} Revenue</Box>
        <Box className=' d-flex flex-column py-1 px-3'>
          <Box className='d-flex p-1'>
            <Box className='col-4 px-0'>Base Fee</Box>
            <Box className='col pr-0 text-truncate'>{displayTooltipContent(getTotalPercentageOfBase(type))}</Box>
          </Box>
          <Box className='d-flex p-1'>
            <Box className='col-4 px-0'>Indirect Fee</Box>
            <Box className='col pr-0 text-truncate'>{displayTooltipContent(getTotalIndirectFee(type))}</Box>
          </Box>
          {<hr style={{ height: '2px', width: '100%', borderWidth: 0, backgroundColor: '#2A364C' }} />}
          <Box className='d-flex p-1'>
            <Box className='col-4 px-0 text-wrap'>Revenue</Box>
            <Box className='col pr-0 text-truncate'>{displayTooltipContent(getRevenue(type))}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

TotalViewRevenue.propTypes = {
  type: PropTypes.string,
  getTotalIndirectFee: PropTypes.func,
  getRevenue: PropTypes.func,
  getTotalPercentageOfBase: PropTypes.func,
  billingStatus: PropTypes.string
};

export default TotalViewRevenue;
