import { Box, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Switch, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModalWithHeader from '../../../Containers/Commons/CustomModalWithHeader';
import { picklistsDataApis } from '../../../services/ApiService';
import { GET } from '../../../services/constantService';
import CustomButton from '../CustomButton';
import LoadingScreen from '../LoadingScreen';
import SuccessScreen from '../SuccessScreen';

const CustomModal = ({ title, onClose, onSubmit, onSuccess, children, isModalOpen, selectedOptions, setSelectedOptions, isLoading, isSuccess, bdStatusOptions = [] }) => {
  const { t } = useTranslation();
  const [loadingHelperText, setLoadingHelperText] = useState('');
  const [instructionHelperText, setInstructionHelperText] = useState('');
  const [selectedBDStatus, setSelectedBDStatus] = useState('');

  const handleOptionChange = option => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(item => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSelectAll = () => {
    if (selectedOptions.length === children.length) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(children.map(child => child.props.value));
    }
  };

  const handleBDStatusChange = event => {
    setSelectedBDStatus(event.target.value);
  };

  const fetchTranslateValue = async () => {
    const res = await picklistsDataApis(GET, 'CLONE_PROJECT_HELPER');

    if (res && res?.data && res.data.length > 0) {
      const helperText = res.data;
      helperText.forEach(text => {
        switch (text.field_value) {
          case 'clone_project_to_project_helper':
            setInstructionHelperText(text.short_desc);
            break;
          case 'clone_project_to_project_loading_helper':
            setLoadingHelperText(text.short_desc);
            break;
        }
      });
    }
  };

  useEffect(() => {
    fetchTranslateValue();
  }, []);

  return (
    <CustomModalWithHeader label={title} closeIcon={true} isOpen={isModalOpen} onClose={onClose} maxWidth='md' style={{ padding: '30px' }}>
      {isLoading && <LoadingScreen title={'Creating Your Project'} description={instructionHelperText} />}
      {!isLoading && !isSuccess && (
        <div style={{ marginTop: '24px' }}>
          <Box
            style={{
              padding: '16px',
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              marginBottom: '24px'
            }}
          >
            <Typography variant='body2' style={{ marginBottom: '5px', fontWeight: 'bold' }}>
              Instructions
            </Typography>
            <Typography variant='body2' style={{ marginBottom: '16px' }}>
              {instructionHelperText}
            </Typography>
          </Box>
          <FormGroup>
            <FormControlLabel control={<Switch className='Switch-component' checked={selectedOptions.length === children.length} onChange={handleSelectAll} />} label='Select All' />

            {children.map(child => (
              <FormControlLabel
                key={child.props.value}
                control={<Switch className='Switch-component' checked={selectedOptions.includes(child.props.value)} onChange={() => handleOptionChange(child.props.value)} />}
                label={child.props.label}
              />
            ))}

            <FormControl size='small' variant='outlined' style={{ marginTop: '1rem', width: '200px' }}>
              <InputLabel size='small' shrink={true} id='bd-status-label'>
                {t('project.projectHeader.mark_bd_as')}
              </InputLabel>
              <Select
                labelId='bd-status-label'
                value={selectedBDStatus || ''}
                onChange={handleBDStatusChange}
                displayEmpty
                size='small'
                label={t('project.projectHeader.mark_bd_as')}
                inputProps={{ 'aria-label': 'BD Status' }}
              >
                <MenuItem disabled value=''>
                  {t('project.projectHeader.select_bd_status')}
                </MenuItem>
                {bdStatusOptions.map(status => (
                  <MenuItem key={status?.field_value} value={status?.short_desc}>
                    {status?.short_desc}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>

          <Grid item xs={12}>
            <div className='w-100 d-flex justify-content-end align-items-center mt-4'>
              <CustomButton type='tertiary-error' variant='text' buttonText='Cancel' style={{ marginRight: '12px' }} onClick={onClose} />
              <CustomButton buttonText='Create Project' style={{ marginRight: '12px' }} onClick={() => onSubmit(selectedBDStatus)} />
            </div>
          </Grid>
        </div>
      )}
      {isSuccess && <SuccessScreen title={t('project.projectCreated')} description={t('project.projectCreatedSuccessMessage')} successBtnText={t('project.viewProject')} onSuccess={onSuccess} />}
    </CustomModalWithHeader>
  );
};

CustomModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  isModalOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  selectedOptions: PropTypes.array,
  setSelectedOptions: PropTypes.func,
  onSuccess: PropTypes.func,
  bdStatusOptions: PropTypes.array
};

export default CustomModal;
