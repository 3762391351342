import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { getValueWithoutHtml } from '../../../../utils/common';
import RichText from '../../../common/RichText';

const BillingForm = props => {
  const { currentValues /* ,currencyDetails */ } = props;
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Opportunity#</InputLabel>
          <span className='page-label-value'>{currentValues.job_number || '--'}</span>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Service Offering</InputLabel>
          <span className='page-label-value'>{currentValues?.service_offering || currentValues.brand || '--'}</span>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Currency</InputLabel>
          <span className='page-label-value'>{currentValues && currentValues?.bd_currency_values ? currentValues?.bd_currency_values?.code : '--'}</span>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Geography</InputLabel>
          <span className='page-label-value'>{(currentValues.markets && currentValues.markets.market) || '--'}</span>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Fee Cap</InputLabel>
          <span className='page-label-value'>{currentValues && currentValues.is_fee_cap ? 'Yes' : 'No'}</span>
        </div>
        {currentValues && currentValues.is_fee_cap === true && (
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Fee Cap Amount</InputLabel>
            <span className='page-label-value'>{currentValues && currentValues.fee_cap_amount ? `${currentValues?.bd_currency_values?.currency_icon} ${currentValues.fee_cap_amount}` : '--'}</span>
          </div>
        )}
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Term Type</InputLabel>
          <span className='page-label-value'>{currentValues && currentValues?.term_type ? currentValues.term_type : '--'}</span>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Intervals</InputLabel>
          <span className='page-label-value'>{currentValues && currentValues.intervals_desc ? currentValues.intervals_desc : '--'}</span>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Billing Region</InputLabel>
          <span className='page-label-value'>{currentValues && currentValues.billing_region ? currentValues.billing_region : '--'}</span>
        </div>
        {currentValues.intervals === 'Other Interval' ? (
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Interval Other</InputLabel>
            <span className='page-label-value'>{currentValues && currentValues.intervals_other ? currentValues.intervals_other : '--'}</span>
          </div>
        ) : null}
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Fee Type</InputLabel>
          <span className='page-label-value'>{currentValues && currentValues.fee_type_desc ? currentValues.fee_type_desc : currentValues.fee_type ? currentValues.fee_type : 'Standard - 1/3'}</span>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Fee Percentage</InputLabel>

          <span className='page-label-value'>{currentValues && currentValues.fee_percentage ? currentValues.fee_percentage : '--'}</span>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view '>
          <InputLabel className='page-label'>Billing Notes</InputLabel>
          {getValueWithoutHtml(currentValues?.billing_notes) ? (
            <div className='page-label-value' style={{ maxHeight: 'fit-content' }}>
              <RichText
                className='input-form-field padding-rich-remove'
                setOpen={false}
                // ref={register}
                placeholder=''
                InputLabelProps={{ focused: true }}
                name='billing_notes'
                variant='outlined'
                defaultValue={currentValues?.billing_notes || ''}
                isView={true}
                displayToolbar={false}
              />
            </div>
          ) : (
            <span className='page-label-value'>{'--'}</span>
          )}
        </div>
      </div>
    </div>
  );
};

BillingForm.propTypes = {
  currentValues: PropTypes.object,
  currencyDetails: PropTypes.object
};

export default BillingForm;
